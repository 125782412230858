<template>
    <b-card-code
      title="Admin Panel"
      no-body
    >
    <div>
      <b-alert
        :show="dismissCountDown"
        dismissible
        variant="success"
        @dismissed="dismissedAlert"
        @dismiss-count-down="countDownChanged"
      >
      {{ alertMsg }}
      </b-alert>
    </div>
    <div>
      <b-alert v-model='alertNoReload' dismissible variant="success">{{alertMsgNR}}</b-alert>
    </div>

    <b-modal id="modalcheckTravellers" :title="'Transaction No.'+tr_title_trav" v-model="modalchTravellers" ok-only centered size="lg">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <b-list-group v-for="(tr, i) in travellersArr" :key="i" class="mb-2" style="box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.45);">
              <b-list-group-item>Title: {{ tr.gender || '-' }}</b-list-group-item>
              <b-list-group-item>Name: {{ tr.customer_name || '-' }}</b-list-group-item>
              <b-list-group-item>Date of birth: {{ tr.date_of_birth || '-' }}</b-list-group-item>
              <b-list-group-item>Age: {{ tr.age || '-' }}</b-list-group-item>
              <b-list-group-item>Date: {{ tr.r_timestamp || '-' }}</b-list-group-item>
            </b-list-group>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal class="modalcheckIndividuals" :title="'Member: '+q__id" v-model="modalchIndividuals" ok-only centered size="lg">
      <div class="container">
        <div class="row">
          <div class="col-12" v-if="IndividualsArr">
            <b-list-group v-for="(tr, i) in IndividualsArr" :key="i" class="mb-2" style="box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.45);">
              <b-list-group-item>Partner: {{ tr.partner || '-' }}</b-list-group-item>
              <b-list-group-item>Customer Name: {{ tr.customer_name || '-' }}</b-list-group-item>
              <b-list-group-item>Date of issue: {{ tr.date_of_issue || '-' }}</b-list-group-item>
              <b-list-group-item>Trip Start: {{ tr.date_of_trip_start || '-' }}</b-list-group-item>
              <b-list-group-item>Trip End: {{ tr.date_of_trip_end || '-' }}</b-list-group-item>
              <b-list-group-item><b>Payable: {{ tr.payable || '-' }}</b></b-list-group-item>
              <b-list-group-item>Gross: {{ tr.gross || '-' }}</b-list-group-item>
              <b-list-group-item>Net: {{ tr.net || '-' }}</b-list-group-item>
            </b-list-group>
          </div>
          <div class="col-12" v-else>
            <p class="lead">Τhere are no outstanding debts</p>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal id="modaljwt" title="Admin Panel" v-model="modalAction" hide-footer centered :no-close-on-backdrop="true" :no-close-on-esc="true" :hide-header-close="true" size="lg">
      <b-form @submit="onSubmit">
        <b-form-group
          id="input_mail"
          label="Email address"
          label-for="input-mail"
        >
          <b-form-input
            id="input-mail"
            type="email"
            placeholder="Enter email"
            v-model="formJWT.username"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="input_pass"
          label="Password"
          label-for="input-pass"
        >
          <b-form-input
            id="input-pass"
            type="password"
            placeholder="Enter password"
            v-model="formJWT.password"
            required
          ></b-form-input>
        </b-form-group>
        <b-button type="submit" variant="primary">Submit</b-button>
        <b-button class="ml-2" variant="outline-secondary" to="landing">Back to Landing</b-button>
      </b-form>
    </b-modal>

    <div v-if="adminUser">
      <b-tabs content-class="mt-2 mb-2">
        <b-tab title="Partners" active>
          <b-card-body>
            <div class="d-flex justify-content-between flex-wrap">
              <!-- sorting  -->
              <b-form-group
                label="Sort"
                label-size="sm"
                label-align-sm="left"
                label-cols-sm="2"
                label-for="sortBySelectP"
                class="mr-1 mb-md-0 align-items-center"
              >
                <b-input-group
                  size="sm"
                >
                  <b-form-select
                    id="sortBySelectP"
                    v-model="sortByPartner"
                    :options="sortOptionsPartners"
                  >
                    <template #first>
                      <option value="">
                        none
                      </option>
                    </template>
                  </b-form-select>
                  <b-form-select
                    v-model="sortDesc"
                    size="sm"
                    :disabled="!sortByPartner"
                  >
                    <option :value="false">
                      Asc
                    </option>
                    <option :value="true">
                      Desc
                    </option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>

              <!-- filter -->
              <b-form-group
                label="Filter"
                label-cols-sm="2"
                label-align-sm="left"
                label-size="sm"
                label-for="filterInputP"
                class="mb-0 align-items-center"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInputP"
                    v-model="filterPartner"
                    type="search"
                    placeholder="Type to Search"
                    debounce=500
                  />
                  <b-input-group-append>
                    <b-button
                      :disabled="!filterPartner"
                      @click="filterPartner = ''"
                    >
                      Clear
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
          </b-card-body>
    
          <b-card-code
            title=""
            no-body
          >
        
            <b-table
              ref="selectableTable"
              striped
              responsive
              class="position-relative"
              :per-page="perPage"
              :current-page="currentPageP"
              :items="itemsPartners"
              :fields="fieldsPartners"
              :sort-by.sync="sortByPartner"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirectionP"
              :filter="filterPartner"
              :filter-included-fields="filterOn"
              @filtered="onFilteredPartner"
              :busy="isBusyPartners"
              >
              <template #table-busy>
                  <div class="d-flex align-items-center justify-content-around my-2">
                  <b-spinner variant="dark" style="width: 3rem; height: 3rem;"></b-spinner>
                  <b-spinner variant="dark" style="width: 3rem; height: 3rem;"></b-spinner>
                  <b-spinner variant="dark" style="width: 3rem; height: 3rem;"></b-spinner>
                  </div>
              </template>
              <template #cell(edit_partner)="data">
                <font-awesome-icon :id="'ep__'+data.item.partner_id" v-b-modal="'mo_'+data.item.partner_id" icon="fas fa-edit" class="text-warning" style="cursor:pointer;"/>
                <b-popover :target="'ep__'+data.item.partner_id" triggers="hover" placement="top">
                  <template #title>Edit partner</template>
                  {{ data.item.partner_company_name }}
                </b-popover>
                <b-modal :id="'mo_'+data.item.partner_id" :title="'Partner: '+data.item.partner_company_name" ok-only @show="getParentCompany(data.item, data.item.partner_parent_id)" :no-close-on-backdrop="true">
                  <b-form @submit="onEditPartner">
                    <h4 class="text-success" v-if="modalpartnerEdit">Partner has been edited</h4>
                    <div v-if="formEditPartner.parent_id && !modalpartnerEdit">
                      <b-form-group
                        id="company_edit"
                        label="Company Name"
                        label-for="input_company_edit"
                      >
                        <b-form-input
                          id="input_company_edit"
                          type="text"
                          v-model="formEditPartner.company_name"
                        ></b-form-input>
                        <span class="text-danger">{{ duplicate }}</span>
                      </b-form-group>

                      <b-form-group id="partnerlevel_edit" label="Level" label-for="v-partnerLevel">
                        <v-select
                          id="v-partnerLevel"
                          v-model="formEditPartner.level"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="partnerLevels"
                          :clearable="false"
                          label="text"
                        />
                      </b-form-group>

                      <b-form-group id="parent_edit" label="Parent" label-for="v-parent">
                        <v-select
                          id="v-parent"
                          v-model="formEditPartner.parent"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="optionsPartners"
                          :clearable="false"
                          label="text"
                          @input="saveParentID(formEditPartner.parent)"
                        />
                      </b-form-group>

                      <b-form-group id="par_active" label="Active" label-for="v-par_active">
                        <v-select
                          id="v-par_active"
                          v-model="formEditPartner.active"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="partnerAct"
                          :clearable="false"
                          label="text"
                        />
                      </b-form-group>

                      <b-button type="submit" variant="primary">Edit Partner</b-button>
                    </div>
                  </b-form>
                </b-modal>
              </template>
              <template #cell(delete_partner)="data">
                <font-awesome-icon :id="'dp__'+data.item.partner_id" icon="fas fa-trash" class="text-danger" style="cursor:pointer;" @click="deletePartner(data.item.partner_id, data.item.partner_company_name)"/>
                <b-popover :target="'dp__'+data.item.partner_id" triggers="hover" placement="top">
                  <template #title>Delete partner</template>
                  {{ data.item.partner_company_name }}
                </b-popover>
              </template>
            </b-table>

            <!-- button Partners -->
            <b-card-body class="demo-inline-spacing pt-0" v-if="itemsPartners.length">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                @click="downloadExcel(itemsPartners,'Partners.xlsx')"
              >
                Partners Excel
              </b-button>
            </b-card-body>

            <template #code>
              {{ codeRowSelectSupport }}
            </template>
          </b-card-code>

          <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
            <!-- page length -->
            <b-form-group
              label="Per Page"
              label-cols="6"
              label-align="left"
              label-size="sm"
              label-for="sortBySelectP"
              class="text-nowrap mb-md-0 mr-1 align-items-center"
            >
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                inline
                :options="pageOptions"
              />
            </b-form-group>

            <!-- pagination -->
            <div>
              <b-pagination
                v-model="currentPageP"
                :total-rows="totalRowsPartner"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0 align-items-center"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </b-card-body>
        </b-tab>
        <b-tab title="Members">
          <b-card-body>
            <div class="d-flex justify-content-between flex-wrap">
              <!-- sorting  -->
              <b-form-group
                label="Sort"
                label-size="sm"
                label-align-sm="left"
                label-cols-sm="2"
                label-for="sortBySelectM"
                class="mr-1 mb-md-0 align-items-center"
              >
                <b-input-group
                  size="sm"
                >
                  <b-form-select
                    id="sortBySelectM"
                    v-model="sortByMember"
                    :options="sortOptionsMembers"
                  >
                    <template #first>
                      <option value="">
                        none
                      </option>
                    </template>
                  </b-form-select>
                  <b-form-select
                    v-model="sortDesc"
                    size="sm"
                    :disabled="!sortByMember"
                  >
                    <option :value="false">
                      Asc
                    </option>
                    <option :value="true">
                      Desc
                    </option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>

              <!-- filter -->
              <b-form-group
                label="Filter"
                label-cols-sm="2"
                label-align-sm="left"
                label-size="sm"
                label-for="filterInputM"
                class="mb-0 align-items-center"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInputM"
                    v-model="filterMember"
                    type="search"
                    placeholder="Type to Search"
                    debounce=500
                  />
                  <b-input-group-append>
                    <b-button
                      :disabled="!filterMember"
                      @click="filterMember = ''"
                    >
                      Clear
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
          </b-card-body>
    
          <b-card-code
            title=""
            no-body
          >
        
            <b-table
              ref="selectableTable"
              striped
              responsive
              class="position-relative"
              :per-page="perPage"
              :current-page="currentPageM"
              :items="itemsMembers"
              :fields="fieldsMembers"
              :sort-by.sync="sortByMember"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirectionM"
              :filter="filterMember"
              :filter-included-fields="filterOn"
              @filtered="onFilteredMember"
              :busy="isBusyMembers"
              >
              <template #table-busy>
                  <div class="d-flex align-items-center justify-content-around my-2">
                  <b-spinner variant="dark" style="width: 3rem; height: 3rem;"></b-spinner>
                  <b-spinner variant="dark" style="width: 3rem; height: 3rem;"></b-spinner>
                  <b-spinner variant="dark" style="width: 3rem; height: 3rem;"></b-spinner>
                  </div>
              </template>
              <template #cell(edit_member)="data">
                <font-awesome-icon :id="'em__'+data.item.member_id" v-b-modal="'mo_'+data.item.member_id" icon="fas fa-edit" class="text-warning" style="cursor:pointer;"/>
                <b-popover :target="'em__'+data.item.member_id" triggers="hover" placement="top">
                  <template #title>Edit member</template>
                  {{ data.item.member_username }}
                </b-popover>
                <b-modal :id="'mo_'+data.item.member_id" :title="'Member: '+data.item.member_username" ok-only :no-close-on-backdrop="true" @show="getMemberDts(data.item)">
                  <b-tabs content-class="2" fill>
                    <b-tab title="Edit Member" active>
                      <b-form @submit="onEditMember">
                        <h4 class="text-success" v-if="modalmemberEdit">Member has been edited</h4>
                        <div v-if="formEditMember.organization_id && !modalmemberEdit">
                          <b-form-group
                            id="uname_edit"
                            label="UserName"
                            label-for="un-edit"
                          >
                            <b-form-input
                              id="un-edit"
                              type="text"
                              v-model="formEditMember.username"
                              required
                            ></b-form-input>
                          </b-form-group>

                          <b-form-group
                            id="email_edit"
                            label="Email"
                            label-for="email-edit"
                          >
                            <b-form-input
                              id="email-edit"
                              type="text"
                              v-model="formEditMember.email"
                              required
                            ></b-form-input>
                            <span class="text-danger">{{ duplicate }}</span>
                          </b-form-group>

                          <b-form-group id="org_edit" label="Organization" label-for="v-organization">
                            <v-select
                              id="v-organization"
                              v-model="formEditMember.parent"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :options="optionsMembers"
                              :clearable="false"
                              label="text"
                              @input="saveMParentID(formEditMember.parent)"
                            />
                          </b-form-group>

                          <b-form-group id="act_edit" label="Active" label-for="v-actm">
                            <v-select
                              id="v-actm"
                              v-model="formEditMember.active"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :clearable="false"
                              label="text"
                              :options="memberAct"
                            />
                          </b-form-group>

                          <b-form-group id="cam_edit" label="Commission Amount" label-for="v-cam-edit">
                            <v-select
                              id="v-cam-edit"
                              v-model="formEditMember.commission_amount"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :clearable="false"
                              label="text"
                              :options="memberCA"
                            />
                          </b-form-group>

                          <b-form-group id="com_edit" label="Commission Operation" label-for="v-com-edit">
                            <v-select
                              id="v-com-edit"
                              v-model="formEditMember.commission_operation"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :clearable="false"
                              label="text"
                              :options="memberCO"
                            />
                          </b-form-group>

                          <b-button type="submit" variant="primary">Edit Member</b-button>
                        </div>
                      </b-form>
                    </b-tab>
                    <b-tab title="Update password">
                      <b-form @submit="onEditMemberPassword(data.item.member_id, $event)">
                        <h4 class="text-success" v-if="modalmemberEdit">Member password has been edited</h4>
                        <div v-else>
                          <b-form-group
                            id="password_new"
                            label="New Password"
                            label-for="pass_nnew"
                          >
                            <b-form-input
                              id="pass_nnew"
                              type="password"
                              v-model="formEditPassword.password"
                              required
                              minlength="8"
                            ></b-form-input>
                          </b-form-group>
                          <b-form-group
                            id="password_repeat"
                            label="Confirm Password"
                            label-for="pass_rrepeat"
                          >
                            <b-form-input
                              id="pass_rrepeat"
                              type="password"
                              v-model="formEditPassword.repeat"
                              required
                              minlength="8"
                            ></b-form-input>
                            <span class="text-danger">{{ passMatch }}</span>
                          </b-form-group>
                          <b-button type="submit" variant="primary">Update Password</b-button>
                        </div>
                      </b-form>
                    </b-tab>
                  </b-tabs>
                </b-modal>
              </template>
              <template #cell(delete_member)="data">
                <font-awesome-icon :id="'dm__'+data.item.member_id" icon="fas fa-trash" class="text-danger" style="cursor:pointer;" @click="deleteMember(data.item.member_id, data.item.member_email)"/>
                <b-popover :target="'dm__'+data.item.member_id" triggers="hover" placement="top">
                  <template #title>Delete member</template>
                  {{ data.item.member_username }}
                </b-popover>
              </template>
            </b-table>

            <!-- button Members -->
            <b-card-body class="demo-inline-spacing pt-0" v-if="itemsMembers.length">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                @click="downloadExcel(itemsMembers,'Members.xlsx')"
              >
                Members Excel
              </b-button>
            </b-card-body>

            <template #code>
              {{ codeRowSelectSupport }}
            </template>
          </b-card-code>

          <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
            <!-- page length -->
            <b-form-group
              label="Per Page"
              label-cols="6"
              label-align="left"
              label-size="sm"
              label-for="sortBySelectM"
              class="text-nowrap mb-md-0 mr-1 align-items-center"
            >
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                inline
                :options="pageOptions"
              />
            </b-form-group>

            <!-- pagination -->
            <div>
              <b-pagination
                v-model="currentPageM"
                :total-rows="totalRowsMember"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0 align-items-center"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </b-card-body>
        </b-tab>
        <b-tab title="Members with Partner">
          <b-card-body>
            <div class="d-flex justify-content-between flex-wrap">
              <!-- sorting  -->
              <b-form-group
                label="Sort"
                label-size="sm"
                label-align-sm="left"
                label-cols-sm="2"
                label-for="sortBySelectMP"
                class="mr-1 mb-md-0 align-items-center"
              >
                <b-input-group
                  size="sm"
                >
                  <b-form-select
                    id="sortBySelectMP"
                    v-model="sortByMemberPartner"
                    :options="sortOptionsMemberPartner"
                  >
                    <template #first>
                      <option value="">
                        none
                      </option>
                    </template>
                  </b-form-select>
                  <b-form-select
                    v-model="sortDesc"
                    size="sm"
                    :disabled="!sortByMemberPartner"
                  >
                    <option :value="false">
                      Asc
                    </option>
                    <option :value="true">
                      Desc
                    </option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>

              <!-- filter -->
              <b-form-group
                label="Filter"
                label-cols-sm="2"
                label-align-sm="left"
                label-size="sm"
                label-for="filterInputPM"
                class="mb-0 align-items-center"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInputPM"
                    v-model="filterPM"
                    type="search"
                    placeholder="Type to Search"
                    debounce=500
                  />
                  <b-input-group-append>
                    <b-button
                      :disabled="!filterPM"
                      @click="filterPM = ''"
                    >
                      Clear
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
          </b-card-body>
    
          <b-card-code
            title=""
            no-body
          >
        
            <b-table
              ref="selectableTable"
              striped
              responsive
              class="position-relative"
              :per-page="perPage"
              :current-page="currentPagePM"
              :items="itemsMemberPartner"
              :fields="fieldsMemberPartner"
              :sort-by.sync="sortByMemberPartner"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirectionPM"
              :filter="filterPM"
              :filter-included-fields="filterOn"
              @filtered="onFilteredMemberPartner"
              :busy="isBusyMemberPartner"
              >
              <template #table-busy>
                  <div class="d-flex align-items-center justify-content-around my-2">
                  <b-spinner variant="dark" style="width: 3rem; height: 3rem;"></b-spinner>
                  <b-spinner variant="dark" style="width: 3rem; height: 3rem;"></b-spinner>
                  <b-spinner variant="dark" style="width: 3rem; height: 3rem;"></b-spinner>
                  </div>
              </template>
              <template #cell(unpaidDebts)="data">
                <b-button size="sm" variant="outline-danger" @click="checkIndividuals(data.item.mp_username, data.item.mbrToken)">Check</b-button>
              </template>
            </b-table>

            <!-- button Members with partner -->
            <b-card-body class="demo-inline-spacing pt-0" v-if="itemsMemberPartner.length">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                @click="downloadExcel(itemsMemberPartner,'Members_with_partner.xlsx')"
              >
                Members with Partner Excel
              </b-button>
            </b-card-body>

            <template #code>
              {{ codeRowSelectSupport }}
            </template>
          </b-card-code>

          <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
            <!-- page length -->
            <b-form-group
              label="Per Page"
              label-cols="6"
              label-align="left"
              label-size="sm"
              label-for="sortBySelectMP"
              class="text-nowrap mb-md-0 mr-1 align-items-center"
            >
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                inline
                :options="pageOptions"
              />
            </b-form-group>

            <!-- pagination -->
            <div>
              <b-pagination
                v-model="currentPagePM"
                :total-rows="totalRowsMemberPartner"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0 align-items-center"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </b-card-body>
        </b-tab>
        <b-tab title="Products">
          <b-card-body>
            <div class="d-flex justify-content-between flex-wrap">
              <!-- sorting  -->
              <b-form-group
                label="Sort"
                label-size="sm"
                label-align-sm="left"
                label-cols-sm="2"
                label-for="sortBySelectPr"
                class="mr-1 mb-md-0 align-items-center"
              >
                <b-input-group
                  size="sm"
                >
                  <b-form-select
                    id="sortBySelectPr"
                    v-model="sortByProducts"
                    :options="sortOptionsProducts"
                  >
                    <template #first>
                      <option value="">
                        none
                      </option>
                    </template>
                  </b-form-select>
                  <b-form-select
                    v-model="sortDesc"
                    size="sm"
                    :disabled="!sortByProducts"
                  >
                    <option :value="false">
                      Asc
                    </option>
                    <option :value="true">
                      Desc
                    </option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>

              <!-- filter -->
              <b-form-group
                label="Filter"
                label-cols-sm="2"
                label-align-sm="left"
                label-size="sm"
                label-for="filterInputPr"
                class="mb-0 align-items-center"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInputPr"
                    v-model="filterProducts"
                    type="search"
                    placeholder="Type to Search"
                    debounce=500
                  />
                  <b-input-group-append>
                    <b-button
                      :disabled="!filterProducts"
                      @click="filterProducts = ''"
                    >
                      Clear
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
          </b-card-body>
    
          <b-card-code
            title=""
            no-body
          >
        
            <b-table
              ref="selectableTable"
              striped
              responsive
              class="position-relative"
              :per-page="perPage"
              :current-page="currentPagePr"
              :items="itemsProducts"
              :fields="fieldsProducts"
              :sort-by.sync="sortByProducts"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirectionPr"
              :filter="filterProducts"
              :filter-included-fields="filterOn"
              @filtered="onFilteredProducts"
              :busy="isBusyProducts"
              >
              <template #table-busy>
                  <div class="d-flex align-items-center justify-content-around my-2">
                  <b-spinner variant="dark" style="width: 3rem; height: 3rem;"></b-spinner>
                  <b-spinner variant="dark" style="width: 3rem; height: 3rem;"></b-spinner>
                  <b-spinner variant="dark" style="width: 3rem; height: 3rem;"></b-spinner>
                  </div>
              </template>
              <template #cell(delete_product)="data">
                <font-awesome-icon :id="'dpr__'+data.item.id" icon="fas fa-trash" class="text-danger" style="cursor:pointer;" @click="deleteProduct(data.item.id, data.item.product, data.item.username)"/>
                <b-popover :target="'dpr__'+data.item.id" triggers="hover" placement="top">
                  <template #title>Delete product</template>
                  {{ data.item.product }} from {{ data.item.username }}
                </b-popover>
              </template>
            </b-table>

            <!-- button Products -->
            <b-card-body class="demo-inline-spacing pt-0" v-if="itemsProducts.length">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                @click="downloadExcel(itemsProducts,'Products.xlsx')"
              >
                Products Excel
              </b-button>
            </b-card-body>

            <template #code>
              {{ codeRowSelectSupport }}
            </template>
          </b-card-code>

          <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
            <!-- page length -->
            <b-form-group
              label="Per Page"
              label-cols="6"
              label-align="left"
              label-size="sm"
              label-for="sortBySelectPr"
              class="text-nowrap mb-md-0 mr-1 align-items-center"
            >
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                inline
                :options="pageOptions"
              />
            </b-form-group>

            <!-- pagination -->
            <div>
              <b-pagination
                v-model="currentPagePr"
                :total-rows="totalRowsProducts"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0 align-items-center"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </b-card-body>
        </b-tab>
        <b-tab title="Transactions">
          <div class="col-md-12 text-center">
            <b-button v-b-modal.modal-addtrans variant="outline-danger" size="sm">Add Transaction</b-button>
            <b-modal id="modal-addtrans" title="Add Transaction" header-text-variant="success" centered size="xl" hide-footer :no-close-on-backdrop="true" :no-close-on-esc="true">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-12" v-if="modaladdedTr">
                    <h4 class="text-success">Transaction has been added</h4>
                  </div>
                  <div class="col-12" v-else>
                    <b-form @submit="addTransaction">
                      <div class="row">
                        <!-- add transaction -->
                        <div class="col-md-6">
                          <b-form-group
                            id="numb_custom"
                            label="Number of Customers"
                            label-for="numb_customs"
                          >
                            <b-form-input
                              id="numb_customs"
                              type="number"
                              v-model.number="formAddTransaction.noc"
                              min="1"
                              required
                            ></b-form-input>
                          </b-form-group>
                        </div>
                        <div class="col-md-6">
                          <b-form-group
                            id="quoteid_inp"
                            label="Quote ID"
                            label-for="quoteid_inps"
                          >
                            <b-form-input
                              id="quoteid_inps"
                              type="number"
                              v-model.number="formAddTransaction.quoteid"
                              min="1"
                              required
                            ></b-form-input>
                            <span class="text-danger">{{ duplicate }}</span>
                          </b-form-group>
                        </div>
                        <div class="col-md-6">
                          <b-form-group
                            id="sell_befalf"
                            label="Sell on behalf of"
                            label-for="sell_befalfs"
                          >
                            <v-select
                              id="sell_befalfs"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :clearable="false"
                              label="text"
                              :options="optionsMembers"
                              v-model="formAddTransaction.parent"
                              @input="updateAgent(formAddTransaction.parent)"
                            />
                          </b-form-group>
                        </div>
                        <div class="col-md-6">
                          <b-form-group
                            id="sell_agent"
                            label="Agent"
                            label-for="sell_agentcv"
                          >
                            <v-select
                              id="sell_agentcv"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :clearable="false"
                              label="text"
                              :options="optionsAgents"
                              v-model="formAddTransaction.agent"
                              @input="updateAgentID(formAddTransaction.agent)"
                            />
                          </b-form-group>
                        </div>
                        <div class="col-md-6">
                          <b-form-group
                            id="productss"
                            label="Product"
                            label-for="productsscv"
                          >
                            <v-select
                              id="productsscv"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :clearable="true"
                              label="text"
                              :options="allProducts"
                              v-model="formAddTransaction.product"
                              @input="updateProductCode(formAddTransaction.product)"
                            />
                          </b-form-group>
                        </div>
                        <div class="col-md-6">
                          <b-form-group
                            id="ext_product"
                            label="External Product"
                            label-for="ext_products"
                          >
                            <b-form-input
                              id="ext_products"
                              type="text"
                              v-model="formAddTransaction.external_product"
                              :disabled="formAddTransaction.product ? true:false"
                            ></b-form-input>
                          </b-form-group>
                        </div>
                        <div class="col-md-6">
                          <b-form-group
                            id="countriesfrom"
                            label="Residence"
                            label-for="countriesfromcv"
                          >
                            <v-select
                              id="countriesfromcv"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :clearable="false"
                              label="text"
                              :options="countrysFrom"
                              v-model="formAddTransaction.from"
                              @input="updateResidence(formAddTransaction.from)"
                            />
                          </b-form-group>
                        </div>
                        <div class="col-md-6">
                          <b-form-group
                            id="countriesto"
                            label="Destination"
                            label-for="countriestocv"
                          >
                            <v-select
                              id="countriestocv"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :clearable="false"
                              label="text"
                              :options="countrysTo"
                              v-model="formAddTransaction.to"
                              @input="updateDestination(formAddTransaction.to)"
                            />
                          </b-form-group>
                        </div>
                        <div class="col-md-6">
                          <b-form-group
                            id="countriesvia"
                            label="Via"
                            label-for="countriesviacv"
                          >
                            <v-select
                              id="countriesviacv"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :clearable="true"
                              label="text"
                              multiple
                              :options="countryVia"
                              v-model="formAddTransaction.via"
                            />
                          </b-form-group>
                        </div>
                        <div class="col-md-6">
                          <b-form-group
                            id="mmemaill"
                            label="Contact Email"
                            label-for="mmemaills"
                          >
                            <b-form-input
                              id="mmemaills"
                              type="email"
                              v-model="formAddTransaction.email"
                              required
                            ></b-form-input>
                          </b-form-group>
                        </div>
                        <div class="col-md-6">
                          <b-form-group
                            id="start_date_a"
                            label="Start Date"
                            label-for="start_date_as"
                          >
                            <b-form-input
                              id="start_date_as"
                              type="date"
                              v-model="formAddTransaction.start_date"
                              data-date="" 
                              data-date-format="DD MMMM YYYY"
                              required
                            ></b-form-input>
                          </b-form-group>
                        </div>
                        <div class="col-md-6">
                          <b-form-group
                            id="end_datee"
                            label="End Date"
                            label-for="end_datees"
                          >
                            <b-form-input
                              id="end_datees"
                              type="date"
                              v-model="formAddTransaction.end_date"
                              data-date="" 
                              data-date-format="DD MMMM YYYY"
                              required
                            ></b-form-input>
                          </b-form-group>
                        </div>
                        <div class="col-md-6">
                          <b-form-group
                            id="address_tr"
                            label="Address"
                            label-for="address_trs"
                          >
                            <b-form-input
                              id="address_trs"
                              type="text"
                              v-model="formAddTransaction.address"
                              required
                            ></b-form-input>
                          </b-form-group>
                        </div>
                        <div class="col-md-6">
                          <b-form-group
                            id="telephone_tr"
                            label="Customer's Telephone"
                            label-for="telephone_trs"
                          >
                            <b-form-input
                              id="telephone_trs"
                              type="number"
                              v-model.number="formAddTransaction.phone"
                              required
                            ></b-form-input>
                          </b-form-group>
                        </div>
                        <div class="col-md-6">
                          <b-form-group
                            id="taxid_tr"
                            label="Customer's Tax Identification Number"
                            label-for="taxid_trs"
                          >
                            <b-form-input
                              id="taxid_trs"
                              type="number"
                              v-model.number="formAddTransaction.taxid"
                              required
                            ></b-form-input>
                          </b-form-group>
                        </div>
                        <div class="col-md-6">
                          <b-form-group
                            id="fullammount_tr"
                            label="Full Amount"
                            label-for="fullammount_trs"
                          >
                            <b-form-input
                              id="fullammount_trs"
                              type="number"
                              v-model.number="formAddTransaction.full_amount"
                              required
                              step=".01"
                            ></b-form-input>
                          </b-form-group>
                        </div>
                        <div class="col-md-6">
                          <b-form-group
                            id="cleanamount_tr"
                            label="Clean Amount"
                            label-for="cleanamount_trs"
                          >
                            <b-form-input
                              id="cleanamount_trs"
                              type="number"
                              v-model.number="formAddTransaction.clean_amount"
                              required
                              step=".01"
                            ></b-form-input>
                          </b-form-group>
                        </div>
                        <div class="col-md-6">
                          <b-form-group
                            id="tea_tr"
                            label="Tea"
                            label-for="tea_trs"
                          >
                            <b-form-input
                              id="tea_trs"
                              type="number"
                              v-model.number="formAddTransaction.tea"
                              required
                              step=".01"
                            ></b-form-input>
                          </b-form-group>
                        </div>
                        <div class="col-md-6">
                          <b-form-group
                            id="pfa_tr"
                            label="Policy Fee Amount"
                            label-for="pfa_trs"
                          >
                            <b-form-input
                              id="pfa_trs"
                              type="number"
                              v-model.number="formAddTransaction.pfa"
                              required
                              step=".01"
                            ></b-form-input>
                          </b-form-group>
                        </div>
                      </div>
                      <!-- dynamic travellers -->
                      <div class="row" v-for="(traveller,i) in formAddTransaction.noc" :key="i">
                        <div class="col-12">
                          <h4 class="text-success mt-1">Traveller {{i+1}}</h4>
                        </div>
                        <div class="col-md-6">
                          <b-form-group
                            :id="'gende_trs'+(i+1)"
                            label="Gender"
                            label-for="'gender_trs'+(i+1)"
                          >
                            <v-select
                              :id="'gender_trs'+(i+1)"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :clearable="false"
                              label="text"
                              :options="genders"
                              v-model="formAddTransaction.gender[traveller]"
                            />
                          </b-form-group>
                        </div>
                        <div class="col-md-6">
                          <b-form-group
                            :id="'cust_full_name'+(i+1)"
                            label="Customer Full Name"
                            :label-for="'cust_full_names'+(i+1)"
                          >
                            <b-form-input
                              :id="'cust_full_names'+(i+1)"
                              type="text"
                              v-model="formAddTransaction.full_name[traveller]"
                              required
                            ></b-form-input>
                          </b-form-group>
                        </div>
                        <div class="col-md-6">
                          <b-form-group
                            :id="'dob_tr'+(i+1)"
                            label="Date Of Birth"
                            :label-for="'dob_trs'+(i+1)"
                          >
                            <b-form-input
                              :id="'dob_trs'+(i+1)"
                              type="date"
                              v-model="formAddTransaction.dob[traveller]"
                              data-date="" 
                              data-date-format="DD MMMM YYYY"
                              required
                            ></b-form-input>
                          </b-form-group>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <b-button type="submit" variant="success" size="sm">Submit</b-button>
                        </div>
                      </div>
                    </b-form>
                  </div>
                </div>
              </div>
            </b-modal>
          </div>
          <b-card-body>
            <div class="d-flex justify-content-between flex-wrap">
              <!-- sorting  -->
              <b-form-group
                label="Sort"
                label-size="sm"
                label-align-sm="left"
                label-cols-sm="2"
                label-for="sortBySelectTr"
                class="mr-1 mb-md-0 align-items-center"
              >
                <b-input-group
                  size="sm"
                >
                  <b-form-select
                    id="sortBySelectPr"
                    v-model="sortByTransactions"
                    :options="sortOptionsTransactions"
                  >
                    <template #first>
                      <option value="">
                        none
                      </option>
                    </template>
                  </b-form-select>
                  <b-form-select
                    v-model="sortDesc"
                    size="sm"
                    :disabled="!sortByTransactions"
                  >
                    <option :value="false">
                      Asc
                    </option>
                    <option :value="true">
                      Desc
                    </option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>

              <!-- filter -->
              <b-form-group
                label="Filter"
                label-cols-sm="2"
                label-align-sm="left"
                label-size="sm"
                label-for="filterInputTr"
                class="mb-0 align-items-center"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInputTr"
                    v-model="filterTransaction"
                    type="search"
                    placeholder="Type to Search"
                    debounce=500
                  />
                  <b-input-group-append>
                    <b-button
                      :disabled="!filterTransaction"
                      @click="filterTransaction = ''"
                    >
                      Clear
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
          </b-card-body>
    
          <b-card-code
            title=""
            no-body
          >
        
            <b-table
              ref="selectableTable"
              striped
              responsive
              class="position-relative"
              :per-page="perPage"
              :current-page="currentPageTr"
              :items="itemsTransactions"
              :fields="fieldsTransactions"
              :sort-by.sync="sortByTransactions"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirectionTr"
              :filter="filterTransaction"
              :filter-included-fields="filterOn"
              @filtered="onFilteredTransactions"
              :busy="isBusyTransactions"
              >
              <template #table-busy>
                  <div class="d-flex align-items-center justify-content-around my-2">
                  <b-spinner variant="dark" style="width: 3rem; height: 3rem;"></b-spinner>
                  <b-spinner variant="dark" style="width: 3rem; height: 3rem;"></b-spinner>
                  <b-spinner variant="dark" style="width: 3rem; height: 3rem;"></b-spinner>
                  </div>
              </template>
              <template #cell(payment)="data">
                <b-dropdown :id="'drop__'+data.item.transactionID"  :text="data.item.transaction_state==='paid' ? 'Paid' : 'Outstanding'" class="m-md-2" size="sm" variant="outline-danger">
                  <b-dropdown-item @click="updateTrState(data.item.transactionID,'null','Outstanding')">Outstanding</b-dropdown-item>
                  <b-dropdown-item @click="updateTrState(data.item.transactionID,'paid','Paid')">Paid</b-dropdown-item>
                </b-dropdown>
              </template>
              <template #cell(travellers)="data">
                <b-button size="sm" variant="outline-secondary" @click="checkTravellers(data.item.transactionID)">Check</b-button>
              </template>
              <template #cell(production)="data">
                <b-button size="sm" variant="outline-secondary" @click="generateProduction(data.item.production)" :disabled="isButtonDisabled">Excel</b-button>
              </template>
            </b-table>

            <!-- button Transactions -->
            <b-card-body class="demo-inline-spacing pt-0" v-if="itemsTransactions.length">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                @click="downloadExcel(itemsTransactions,'Transactions.xlsx')"
              >
                Transactions Excel
              </b-button>
            </b-card-body>

            <template #code>
              {{ codeRowSelectSupport }}
            </template>
          </b-card-code>

          <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
            <!-- page length -->
            <b-form-group
              label="Per Page"
              label-cols="6"
              label-align="left"
              label-size="sm"
              label-for="sortBySelectPr"
              class="text-nowrap mb-md-0 mr-1 align-items-center"
            >
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                inline
                :options="pageOptions"
              />
            </b-form-group>

            <!-- pagination -->
            <div>
              <b-pagination
                v-model="currentPageTr"
                :total-rows="totalRowsTransactions"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0 align-items-center"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </b-card-body>
        </b-tab>
        <b-tab title="Expired Products">
          <b-card-body>
            <div class="d-flex justify-content-between flex-wrap">
              <!-- sorting  -->
              <b-form-group
                label="Sort"
                label-size="sm"
                label-align-sm="left"
                label-cols-sm="2"
                label-for="sortBySelectExp"
                class="mr-1 mb-md-0 align-items-center"
              >
                <b-input-group
                  size="sm"
                >
                  <b-form-select
                    id="sortBySelectExp"
                    v-model="sortByExpired"
                    :options="sortOptionsExp"
                  >
                    <template #first>
                      <option value="">
                        none
                      </option>
                    </template>
                  </b-form-select>
                  <b-form-select
                    v-model="sortDesc"
                    size="sm"
                    :disabled="!sortByExpired"
                  >
                    <option :value="false">
                      Asc
                    </option>
                    <option :value="true">
                      Desc
                    </option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>

              <!-- filter -->
              <b-form-group
                label="Filter"
                label-cols-sm="2"
                label-align-sm="left"
                label-size="sm"
                label-for="filterInputExp"
                class="mb-0 align-items-center"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInputExp"
                    v-model="filterExpProd"
                    type="search"
                    placeholder="Type to Search"
                    debounce=500
                  />
                  <b-input-group-append>
                    <b-button
                      :disabled="!filterExpProd"
                      @click="filterExpProd = ''"
                    >
                      Clear
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
          </b-card-body>
    
          <b-card-code
            title=""
            no-body
          >
        
            <b-table
              ref="selectableTable"
              striped
              responsive
              class="position-relative"
              :per-page="perPage"
              :current-page="currentPageExp"
              :items="itemsExpiredProducts"
              :fields="fieldsExpProds"
              :sort-by.sync="sortByExpired"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirectionExp"
              :filter="filterExpProd"
              :filter-included-fields="filterOn"
              @filtered="onFilteredExp"
              :busy="isBusyExpiredProducts"
              >
              <template #table-busy>
                  <div class="d-flex align-items-center justify-content-around my-2">
                  <b-spinner variant="dark" style="width: 3rem; height: 3rem;"></b-spinner>
                  <b-spinner variant="dark" style="width: 3rem; height: 3rem;"></b-spinner>
                  <b-spinner variant="dark" style="width: 3rem; height: 3rem;"></b-spinner>
                  </div>
              </template>
            </b-table>

            <!-- button Expired Products -->
            <b-card-body class="demo-inline-spacing pt-0" v-if="itemsExpiredProducts.length">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                @click="downloadExcel(itemsExpiredProducts,'Expired_products.xlsx')"
              >
              Expired Products Excel
              </b-button>
            </b-card-body>

            <template #code>
              {{ codeRowSelectSupport }}
            </template>
          </b-card-code>

          <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
            <!-- page length -->
            <b-form-group
              label="Per Page"
              label-cols="6"
              label-align="left"
              label-size="sm"
              label-for="sortBySelectExp"
              class="text-nowrap mb-md-0 mr-1 align-items-center"
            >
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                inline
                :options="pageOptions"
              />
            </b-form-group>

            <!-- pagination -->
            <div>
              <b-pagination
                v-model="currentPageExp"
                :total-rows="totalRowsExpProducts"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0 align-items-center"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </b-card-body>
        </b-tab>
        <b-tab title="Member Commissions">
          <b-card-body>
            <div class="d-flex justify-content-between flex-wrap">
              <!-- sorting  -->
              <b-form-group
                label="Sort"
                label-size="sm"
                label-align-sm="left"
                label-cols-sm="2"
                label-for="sortBySelectCommi"
                class="mr-1 mb-md-0 align-items-center"
              >
                <b-input-group
                  size="sm"
                >
                  <b-form-select
                    id="sortBySelectCommi"
                    v-model="sortByCommi"
                    :options="sortOptionsCommi"
                  >
                    <template #first>
                      <option value="">
                        none
                      </option>
                    </template>
                  </b-form-select>
                  <b-form-select
                    v-model="sortDesc"
                    size="sm"
                    :disabled="!sortByCommi"
                  >
                    <option :value="false">
                      Asc
                    </option>
                    <option :value="true">
                      Desc
                    </option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>

              <!-- filter -->
              <b-form-group
                label="Filter"
                label-cols-sm="2"
                label-align-sm="left"
                label-size="sm"
                label-for="filterInputCommi"
                class="mb-0 align-items-center"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInputCommi"
                    v-model="filterExpCommi"
                    type="search"
                    placeholder="Type to Search"
                    debounce=500
                  />
                  <b-input-group-append>
                    <b-button
                      :disabled="!filterExpCommi"
                      @click="filterExpCommi = ''"
                    >
                      Clear
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
          </b-card-body>
    
          <b-card-code
            title=""
            no-body
          >
        
            <b-table
              ref="selectableTable"
              striped
              responsive
              class="position-relative"
              :per-page="perPage"
              :current-page="currentPageCommi"
              :items="itemsMembersCommi"
              :fields="fieldsMembCommi"
              :sort-by.sync="sortByCommi"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirectionCommi"
              :filter="filterExpCommi"
              :filter-included-fields="filterOn"
              @filtered="onFilteredCommi"
              :busy="isBusyCommi"
              >
              <template #table-busy>
                  <div class="d-flex align-items-center justify-content-around my-2">
                  <b-spinner variant="dark" style="width: 3rem; height: 3rem;"></b-spinner>
                  <b-spinner variant="dark" style="width: 3rem; height: 3rem;"></b-spinner>
                  <b-spinner variant="dark" style="width: 3rem; height: 3rem;"></b-spinner>
                  </div>
              </template>
            </b-table>

            <!-- button Member Commissions -->
            <b-card-body class="demo-inline-spacing pt-0" v-if="itemsMembersCommi.length">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                @click="downloadExcel(itemsMembersCommi,'Member_commissions.xlsx')"
              >
              Member Commissions Excel
              </b-button>
            </b-card-body>

            <template #code>
              {{ codeRowSelectSupport }}
            </template>
          </b-card-code>

          <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
            <!-- page length -->
            <b-form-group
              label="Per Page"
              label-cols="6"
              label-align="left"
              label-size="sm"
              label-for="sortBySelectCommi"
              class="text-nowrap mb-md-0 mr-1 align-items-center"
            >
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                inline
                :options="pageOptions"
              />
            </b-form-group>

            <!-- pagination -->
            <div>
              <b-pagination
                v-model="currentPageCommi"
                :total-rows="totalRowsCommi"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0 align-items-center"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </b-card-body>
        </b-tab>
        <b-tab title="EU Sanctions">
          <b-card-body>
            <div class="d-flex justify-content-between flex-wrap">
              <!-- sorting  -->
              <b-form-group
                label="Sort"
                label-size="sm"
                label-align-sm="left"
                label-cols-sm="2"
                label-for="sortBySelectSanctions"
                class="mr-1 mb-md-0 align-items-center"
              >
                <b-input-group
                  size="sm"
                >
                  <b-form-select
                    id="sortBySelectSanctions"
                    v-model="sortBySanctions"
                    :options="sortOptionsSanctions"
                  >
                    <template #first>
                      <option value="">
                        none
                      </option>
                    </template>
                  </b-form-select>
                  <b-form-select
                    v-model="sortDesc"
                    size="sm"
                    :disabled="!sortBySanctions"
                  >
                    <option :value="false">
                      Asc
                    </option>
                    <option :value="true">
                      Desc
                    </option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>

              <!-- filter -->
              <b-form-group
                label="Filter"
                label-cols-sm="2"
                label-align-sm="left"
                label-size="sm"
                label-for="filterInputSanctions"
                class="mb-0 align-items-center"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInputSanctions"
                    v-model="filterExpSanctions"
                    type="search"
                    placeholder="Type to Search"
                    debounce=500
                  />
                  <b-input-group-append>
                    <b-button
                      :disabled="!filterExpSanctions"
                      @click="filterExpSanctions = ''"
                    >
                      Clear
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
          </b-card-body>
    
          <b-card-code
            title=""
            no-body
          >
        
            <b-table
              ref="selectableTable"
              striped
              responsive
              class="position-relative"
              :per-page="perPage"
              :current-page="currentPageSanctions"
              :items="itemsSanctions"
              :fields="fieldsSSanctions"
              :sort-by.sync="sortBySanctions"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirectionSanctions"
              :filter="filterExpSanctions"
              :filter-included-fields="filterOn"
              @filtered="onFilteredSanctions"
              :busy="isBusySanctions"
              >
              <template #table-busy>
                  <div class="d-flex align-items-center justify-content-around my-2">
                  <b-spinner variant="dark" style="width: 3rem; height: 3rem;"></b-spinner>
                  <b-spinner variant="dark" style="width: 3rem; height: 3rem;"></b-spinner>
                  <b-spinner variant="dark" style="width: 3rem; height: 3rem;"></b-spinner>
                  </div>
              </template>
              <template #cell(wholeName)="data">
                <p size="sm" v-html="newLinesNames(data.item.wholeName)"></p>
              </template>
            </b-table>

            <!-- button Sanctions -->
            <b-card-body class="demo-inline-spacing pt-0" v-if="itemsSanctions.length">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                @click="downloadExcel(itemsSanctions,'Sanctions.xlsx')"
              >
              Sanctions Excel
              </b-button>
            </b-card-body>

            <template #code>
              {{ codeRowSelectSupport }}
            </template>
          </b-card-code>

          <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
            <!-- page length -->
            <b-form-group
              label="Per Page"
              label-cols="6"
              label-align="left"
              label-size="sm"
              label-for="sortBySelectSanctions"
              class="text-nowrap mb-md-0 mr-1 align-items-center"
            >
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                inline
                :options="pageOptions"
              />
            </b-form-group>

            <!-- pagination -->
            <div>
              <b-pagination
                v-model="currentPageSanctions"
                :total-rows="totalRowsSanctions"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0 align-items-center"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </b-card-body>
        </b-tab>
        <b-tab title="Unpaid Debts">
          <b-card-body>
            <div class="d-flex justify-content-between flex-wrap">
              <!-- sorting  -->
              <b-form-group
                label="Sort"
                label-size="sm"
                label-align-sm="left"
                label-cols-sm="2"
                label-for="sortBySelectDebts"
                class="mr-1 mb-md-0 align-items-center"
              >
                <b-input-group
                  size="sm"
                >
                  <b-form-select
                    id="sortBySelectDebts"
                    v-model="sortByDebts"
                    :options="sortOptionsDebts"
                  >
                    <template #first>
                      <option value="">
                        none
                      </option>
                    </template>
                  </b-form-select>
                  <b-form-select
                    v-model="sortDesc"
                    size="sm"
                    :disabled="!sortByDebts"
                  >
                    <option :value="false">
                      Asc
                    </option>
                    <option :value="true">
                      Desc
                    </option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>

              <!-- filter -->
              <b-form-group
                label="Filter"
                label-cols-sm="2"
                label-align-sm="left"
                label-size="sm"
                label-for="filterInputDebts"
                class="mb-0 align-items-center"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInputDebts"
                    v-model="filterExpDebts"
                    type="search"
                    placeholder="Type to Search"
                    debounce=500
                  />
                  <b-input-group-append>
                    <b-button
                      :disabled="!filterExpDebts"
                      @click="filterExpDebts = ''"
                    >
                      Clear
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
          </b-card-body>
    
          <b-card-code
            title=""
            no-body
          >
            <b-table
              ref="selectableTable"
              striped
              responsive
              class="position-relative"
              :per-page="perPage"
              :current-page="currentPageDebts"
              :items="itemsDebts"
              :fields="fieldsDebts"
              :sort-by.sync="sortByDebts"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirectionDebts"
              :filter="filterExpDebts"
              :filter-included-fields="filterOn"
              @filtered="onFilteredDebts"
              :busy="isBusyDebts"
              >
              <template #table-busy>
                  <div class="d-flex align-items-center justify-content-around my-2">
                  <b-spinner variant="dark" style="width: 3rem; height: 3rem;"></b-spinner>
                  <b-spinner variant="dark" style="width: 3rem; height: 3rem;"></b-spinner>
                  <b-spinner variant="dark" style="width: 3rem; height: 3rem;"></b-spinner>
                  </div>
              </template>
            </b-table>

            <!-- button Unpaid Debts -->
            <b-card-body class="demo-inline-spacing pt-0" v-if="itemsDebts.length">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                @click="downloadExcel(itemsDebts,'Unpaid_debts.xlsx')"
              >
              Unpaid Debts Excel
              </b-button>
            </b-card-body>

            <template #code>
              {{ codeRowSelectSupport }}
            </template>
          </b-card-code>

          <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
            <!-- page length -->
            <b-form-group
              label="Per Page"
              label-cols="6"
              label-align="left"
              label-size="sm"
              label-for="sortBySelectDebts"
              class="text-nowrap mb-md-0 mr-1 align-items-center"
            >
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                inline
                :options="pageOptions"
              />
            </b-form-group>

            <!-- pagination -->
            <div>
              <b-pagination
                v-model="currentPageDebts"
                :total-rows="totalRowsDebts"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0 align-items-center"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </b-card-body>
        </b-tab>
        <b-tab title="Permissions">
          <b-card-body>
            <div class="d-flex justify-content-between flex-wrap">
              <!-- sorting  -->
              <b-form-group
                label="Sort"
                label-size="sm"
                label-align-sm="left"
                label-cols-sm="2"
                label-for="sortBySelectPerm"
                class="mr-1 mb-md-0 align-items-center"
              >
                <b-input-group
                  size="sm"
                >
                  <b-form-select
                    id="sortBySelectPerm"
                    v-model="sortByPermissions"
                    :options="sortOptionsPermissions"
                  >
                    <template #first>
                      <option value="">
                        none
                      </option>
                    </template>
                  </b-form-select>
                  <b-form-select
                    v-model="sortDesc"
                    size="sm"
                    :disabled="!sortByPermissions"
                  >
                    <option :value="false">
                      Asc
                    </option>
                    <option :value="true">
                      Desc
                    </option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>

              <!-- filter -->
              <b-form-group
                label="Filter"
                label-cols-sm="2"
                label-align-sm="left"
                label-size="sm"
                label-for="filterInputPermissions"
                class="mb-0 align-items-center"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInputPermissions"
                    v-model="filterPermissions"
                    type="search"
                    placeholder="Type to Search"
                    debounce=500
                  />
                  <b-input-group-append>
                    <b-button
                      :disabled="!filterPermissions"
                      @click="filterPermissions = ''"
                    >
                      Clear
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
          </b-card-body>
    
          <b-card-code
            title=""
            no-body
          >
            <b-table
              ref="selectableTable"
              striped
              responsive
              class="position-relative"
              :per-page="perPage"
              :current-page="currentPagePermissions"
              :items="itemsPermissions"
              :fields="fieldsPermissions"
              :sort-by.sync="sortByPermissions"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirectionPermissions"
              :filter="filterPermissions"
              :filter-included-fields="filterOn"
              @filtered="onFilteredPermissions"
              :busy="isBusyPermissions"
              >
              <template #table-busy>
                  <div class="d-flex align-items-center justify-content-around my-2">
                  <b-spinner variant="dark" style="width: 3rem; height: 3rem;"></b-spinner>
                  <b-spinner variant="dark" style="width: 3rem; height: 3rem;"></b-spinner>
                  <b-spinner variant="dark" style="width: 3rem; height: 3rem;"></b-spinner>
                  </div>
              </template>
              <template #cell(state)="data">
                <b-button size="sm" :variant='`${data.item.state==="enabled" ? "outline-success" : "outline-danger"}`' v-b-modal="`modalp_${data.item.idper}`">{{ data.item.state }}</b-button>
                <b-modal ref="modalp_" :id="`modalp_${data.item.idper}`" :title="`Member: ${data.item.username}`" hide-footer size="lg">
                  <div class="container">
                    <div class="row">
                      <div class="col-12">
                        <p class="lead">Permission: <b>{{ data.item.permission_name }}</b></p>
                      </div>
                    </div>
                    <b-form class="row" @submit.prevent="editPermission(data.item.memberID, data.item.permission_name, formPermission)">
                      <div class="col-sm-6">
                        <b-form-select v-model="formPermission.state = data.item.state">
                          <b-form-select-option value="enabled">Enabled</b-form-select-option>
                          <b-form-select-option value="disabled">Disabled</b-form-select-option>
                        </b-form-select>
                      </div>
                      <div class="col-sm-6">
                        <b-form-input v-model="formPermission.reason" minlength="4" placeholder="Reason" required></b-form-input>
                      </div>
                      <div class="col-12 mt-1">
                        <b-button type="submit" variant="primary">Submit</b-button>
                      </div>
                    </b-form>
                  </div>
                </b-modal>
              </template>
            </b-table>

            <!-- button Permissions -->
            <b-card-body class="demo-inline-spacing pt-0" v-if="itemsPermissions.length">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                @click="downloadExcel(itemsPermissions,'Permissions.xlsx')"
              >
              Permissions Excel
              </b-button>
            </b-card-body>

            <template #code>
              {{ codeRowSelectSupport }}
            </template>
          </b-card-code>

          <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
            <!-- page length -->
            <b-form-group
              label="Per Page"
              label-cols="6"
              label-align="left"
              label-size="sm"
              label-for="sortBySelectPerm"
              class="text-nowrap mb-md-0 mr-1 align-items-center"
            >
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                inline
                :options="pageOptions"
              />
            </b-form-group>

            <!-- pagination -->
            <div>
              <b-pagination
                v-model="currentPagePermissions"
                :total-rows="totalRowsPermissions"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0 align-items-center"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </b-card-body>
        </b-tab>
        <!--  -->
        <b-tab title="Reservations">
          <b-card-body>
            <div class="d-flex justify-content-between flex-wrap">
              <!-- sorting  -->
              <b-form-group
                label="Sort"
                label-size="sm"
                label-align-sm="left"
                label-cols-sm="2"
                label-for="sortBySelectReserv"
                class="mr-1 mb-md-0 align-items-center"
              >
                <b-input-group
                  size="sm"
                >
                  <b-form-select
                    id="sortBySelectReserv"
                    v-model="sortByReserv"
                    :options="sortOptionsReserv"
                  >
                    <template #first>
                      <option value="">
                        none
                      </option>
                    </template>
                  </b-form-select>
                  <b-form-select
                    v-model="sortDesc"
                    size="sm"
                    :disabled="!sortByReserv"
                  >
                    <option :value="false">
                      Asc
                    </option>
                    <option :value="true">
                      Desc
                    </option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>

              <!-- filter -->
              <b-form-group
                label="Filter"
                label-cols-sm="2"
                label-align-sm="left"
                label-size="sm"
                label-for="filterInputReserv"
                class="mb-0 align-items-center"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInputReserv"
                    v-model="filterReserv"
                    type="search"
                    placeholder="Type to Search"
                    debounce=500
                  />
                  <b-input-group-append>
                    <b-button
                      :disabled="!filterReserv"
                      @click="filterReserv = ''"
                    >
                      Clear
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
          </b-card-body>
    
          <b-card-code
            title=""
            no-body
          >
            <b-table
              ref="selectableTable"
              striped
              responsive
              class="position-relative"
              :per-page="perPage"
              :current-page="currentPageReserv"
              :items="itemsReserv"
              :fields="fieldsReserv"
              :sort-by.sync="sortByReserv"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirectionReserv"
              :filter="filterReserv"
              :filter-included-fields="filterOn"
              @filtered="onFilteredReserv"
              :busy="isBusyReserv"
              >
              <template #table-busy>
                  <div class="d-flex align-items-center justify-content-around my-2">
                  <b-spinner variant="dark" style="width: 3rem; height: 3rem;"></b-spinner>
                  <b-spinner variant="dark" style="width: 3rem; height: 3rem;"></b-spinner>
                  <b-spinner variant="dark" style="width: 3rem; height: 3rem;"></b-spinner>
                  </div>
              </template>
              <template #cell(order_code)="data">
                <a v-if="data.item.order_code && data.item.state=='unpaid'" :id="'checkoutlink_'+data.item.transactionID" class="btn btn-sm btn-outline-success" target="_blank" :href="`https://www.vivapayments.com/web2?ref=${data.item.order_code}`">Checkout</a>
                <span v-else> - </span>
              </template>
              <template #cell(on_behalf_of)="data">
                <b-button v-if="data.item.order_code && data.item.state=='unpaid'" size="sm" variant='outline-danger' @click="cnlbtnF(data.item.transactionID)">Cancel</b-button>
                <span v-else> - </span>
              </template>
            </b-table>

            <!-- button Reservations -->
            <b-card-body class="demo-inline-spacing pt-0" v-if="itemsReserv.length">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                @click="downloadExcel(itemsReserv,'Reservations.xlsx')"
              >
              Reservations Excel
              </b-button>
            </b-card-body>

            <template #code>
              {{ codeRowSelectSupport }}
            </template>
          </b-card-code>

          <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
            <!-- page length -->
            <b-form-group
              label="Per Page"
              label-cols="6"
              label-align="left"
              label-size="sm"
              label-for="sortBySelectReserv"
              class="text-nowrap mb-md-0 mr-1 align-items-center"
            >
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                inline
                :options="pageOptions"
              />
            </b-form-group>

            <!-- pagination -->
            <div>
              <b-pagination
                v-model="currentPageReserv"
                :total-rows="totalRowsReserv"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0 align-items-center"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </b-card-body>
        </b-tab>
        <!--  -->
      </b-tabs>

      <b-tabs content-class="mt-2 mb-2">
        <b-tab active>
          <template #title>
            <font-awesome-icon :icon="['fas', 'plus']" /> Partner
          </template>
          <div class="container-fluid">
            <div class="row">
              <div class="col-12">
                <b-form @submit="addPartnerForm">
                  <div class="row">
                    <div class="col-md-6">
                      <b-form-group
                        id="add_comp"
                        label="Company Name"
                        label-for="addcompany"
                      >
                        <b-form-input
                          id="addcompany"
                          type="text"
                          placeholder="Company Name"
                          v-model="formAddPartner.company_name"
                          required
                          minlength="4"
                        ></b-form-input>
                        <span class="text-danger">{{ duplicate }}</span>
                      </b-form-group>
                    </div>
                    <div class="col-md-6">
                      <b-form-group
                        id="add_level"
                        label="Level"
                        label-for="addlvl"
                      >
                        <v-select
                          id="addlvl"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :clearable="false"
                          label="text"
                          :options="partnerLevels"
                          v-model="formAddPartner.level"
                        />
                      </b-form-group>
                    </div>
                    <div class="col-md-6">
                      <b-form-group
                        id="add_p"
                        label="Parent"
                        label-for="addpaarent"
                      >
                        <v-select
                          id="addpaarent"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :clearable="false"
                          label="text"
                          :options="optionsMembers"
                          v-model="formAddPartner.parent"
                          @input="updateParent(formAddPartner.parent)"
                        />
                      </b-form-group>
                    </div>
                    <div class="col-md-6">
                      <b-form-group
                        id="add_act"
                        label="Active"
                        label-for="addpaact"
                      >
                        <v-select
                          id="addpaact"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :clearable="false"
                          label="text"
                          :options="memberAct"
                          v-model="formAddPartner.active"
                        />
                      </b-form-group>
                    </div>
                    <div class="col-md-12">
                      <b-button type="submit" variant="primary" size="sm">Add Partner</b-button>
                    </div>
                  </div>
                </b-form>
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            <font-awesome-icon :icon="['fas', 'plus']" /> Member
          </template>
          <div class="container-fluid">
            <div class="row">
              <div class="col-12">
                <b-form @submit="addPMemberForm">
                  <div class="row">
                    <div class="col-md-6">
                      <b-form-group
                        id="add_mm_u"
                        label="Username"
                        label-for="add_user_mem"
                      >
                        <b-form-input
                          id="add_user_mem"
                          type="text"
                          placeholder="Username"
                          v-model="formAddMember.username"
                          required
                          minlength="4"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="col-md-6">
                      <b-form-group
                        id="add_mm_em"
                        label="Email"
                        label-for="add_user_memail"
                      >
                        <b-form-input
                          id="add_user_memail"
                          type="email"
                          placeholder="Email"
                          v-model="formAddMember.email"
                          required
                          minlength="4"
                        ></b-form-input>
                        <span class="text-danger">{{ duplicate }}</span>
                      </b-form-group>
                    </div>
                    <div class="col-md-6">
                      <b-form-group
                        id="add_mm_p"
                        label="Password"
                        label-for="add_user_mep"
                      >
                        <b-form-input
                          id="add_user_mep"
                          type="password"
                          placeholder="Password"
                          v-model="formAddMember.password"
                          required
                          minlength="8"
                        ></b-form-input>
                        <span class="text-danger">{{ passMatchMember }}</span>
                      </b-form-group>
                    </div>
                    <div class="col-md-6">
                      <b-form-group
                        id="add_mm_p"
                        label="Confirm Password"
                        label-for="add_user_mepc"
                      >
                        <b-form-input
                          id="add_user_mepc"
                          type="password"
                          placeholder="Password"
                          v-model="formAddMember.password_confirm"
                          required
                          minlength="8"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="col-md-6">
                      <b-form-group
                        id="add_pr_ac"
                        label="Active"
                        label-for="add_member_oorg"
                      >
                        <v-select
                          id="add_member_oorg"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :clearable="false"
                          label="text"
                          :options="memberAddArr"
                          v-model="formAddMember.active"
                        />
                      </b-form-group>
                    </div>
                    <div class="col-md-6">
                      <b-form-group
                        id="add_pr_org"
                        label="Organization"
                        label-for="add_member_act"
                      >
                        <v-select
                          id="add_member_act"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :clearable="false"
                          label="text"
                          :options="optionsPartners"
                          v-model="formAddMember.parent"
                          @input="updateOrg(formAddMember.parent)"
                        />
                      </b-form-group>
                    </div>
                    <div class="col-md-6">
                      <b-form-group 
                        id="add_pr_ca" 
                        label="Commission Amount" 
                        label-for="v_member_ca">
                        <v-select
                          id="v_member_ca"
                          v-model="formAddMember.commission_amount"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :clearable="false"
                          label="text"
                          :options="memberCA"
                        />
                      </b-form-group>
                    </div>
                    <div class="col-md-6">
                      <b-form-group 
                        id="com_coper" 
                        label="Commission Operation" 
                        label-for="v_m_co">
                        <v-select
                          id="v_m_co"
                          v-model="formAddMember.commission_operation"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :clearable="false"
                          label="text"
                          :options="memberCO"
                        />
                      </b-form-group>
                    </div>
                    <!-- member Metadata -->
                    <div class="col-md-6">
                      <b-form-group
                        id="fullname_mm"
                        label="Full Name"
                        label-for="add_fullname_mepc"
                      >
                        <b-form-input
                          id="add_fullname_mepc"
                          type="text"
                          placeholder="Full Name"
                          v-model="formAddMember.fullname"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="col-md-6">
                      <b-form-group
                        id="phone_mm"
                        label="Phone"
                        label-for="add_phone_mepc"
                      >
                        <b-form-input
                          id="add_phone_mepc"
                          type="number"
                          placeholder="Phone"
                          v-model="formAddMember.phone"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="col-md-6">
                      <b-form-group
                        id="address_mm"
                        label="Address"
                        label-for="add_address_mepc"
                      >
                        <b-form-input
                          id="add_address_mepc"
                          type="text"
                          placeholder="Address"
                          v-model="formAddMember.address"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="col-md-6">
                      <b-form-group
                        id="zipcode_mm"
                        label="Zipcode"
                        label-for="add_zipcode_mepc"
                      >
                        <b-form-input
                          id="add_zipcode_mepc"
                          type="text"
                          placeholder="Zipcode"
                          v-model="formAddMember.zipcode"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="col-md-6">
                      <b-form-group
                        id="city_mm"
                        label="City"
                        label-for="add_city_mepc"
                      >
                        <b-form-input
                          id="add_city_mepc"
                          type="text"
                          placeholder="City"
                          v-model="formAddMember.city"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="col-md-6">
                      <b-form-group 
                        id="com_cccountry" 
                        label="Country" 
                        label-for="add_cntry_mepc">
                        <b-form-input
                          id="add_cntry_mepc"
                          type="text"
                          placeholder="Country"
                          v-model="formAddMember.country"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="col-md-12">
                      <b-button type="submit" variant="primary" size="sm">Add Member</b-button>
                    </div>
                  </div>
                </b-form>
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            <font-awesome-icon :icon="['fas', 'plus']" /> Products
          </template>
          <div class="container-fluid">
            <div class="row">
              <div class="col-12">
                <b-form @submit="addPProductForm">
                  <div class="row">
                    <div class="col-md-6">
                      <b-form-group
                        id="add_pr_m"
                        label="Member"
                        label-for="add_prod_mem"
                      >
                        <v-select
                          id="add_prod_mem"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :clearable="false"
                          label="text"
                          :options="optionsMembers"
                          v-model="formAddProducts.parent"
                          @input="updateMember(formAddProducts.parent)"
                        />
                      </b-form-group>
                      <span class="text-danger">{{ duplicate }}</span>
                    </div>
                    <div class="col-md-6">
                      <b-form-group
                        id="add_pr_p"
                        label="Products"
                        label-for="add_prod_s"
                      >
                        <v-select
                          id="add_prod_s"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :clearable="false"
                          label="text"
                          multiple
                          :options="allProducts"
                          v-model="formAddProducts.product_code"
                          @input="updatePrCode(formAddProducts.product_code)"
                        />
                      </b-form-group>
                    </div>
                    <div class="col-md-12">
                      <b-button type="submit" variant="primary" size="sm">Add Products</b-button>
                    </div>
                  </div>
                </b-form>
              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </div>
    
      <template #code>
        {{ codeKitchenSink }}
      </template>
    </b-card-code>
</template>

<script>
import jwt from 'jsonwebtoken'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { cookie, token_id, countrysFrom, countrysTo, countryVia, products, downloadExcelMixin } from '@/products';
import {
  BTable, BAvatar, BBadge, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BCardBody, BDropdown, BDropdownItem, BAlert, BSpinner, BTab, BTabs, BForm, BListGroup, BListGroupItem, BPopover, BFormSelectOption
} from 'bootstrap-vue'
import { codeKitchenSink } from '../table/bs-table/code'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { codeRowSelectSupport } from '../table/bs-table/code'

export default {
  mixins: [downloadExcelMixin],
  components: {
    BCardCode,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BDropdown,
    BDropdownItem,
    BAlert,
    vSelect,
    BSpinner,
    BTab,
    BTabs,
    BForm,
    BListGroup, 
    BListGroupItem,
    BPopover,
    BFormSelectOption
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      products,
      modaladdedTr:false,
      isButtonDisabled: false,
      countrysFrom, 
      countrysTo, 
      countryVia,
      duplicate:'',
      passMatchMember:'',
      passMatch: '',
      modalpartnerEdit: false,
      modalmemberEdit: false,
      partnerLevels: [1,2,3],
      partnerAct: ["yes","no"],
      memberAct: ["yes","no"],
      memberAddArr: ["yes","no"],
      memberCO: ["Percentage","NULL"],
      memberCA: ["0","1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19","20","21","22","23","24","25","26","27","28","29","30","31","32","33","34","35","36","37","38","39","40","41","42","43","44","45"],
      alertMsg: '',
      alertMsgNR: '',
      alertNoReload: false,
      travellersArr: null,
      IndividualsArr: null,
      tr_title: '',
      tr_title_trav: '',
      q__id: '',
      modalchTravellers: false,
      modalchIndividuals: false,
      checkTrInput: '',
      formJWT: {
        username: '',
        password: '',
      },
      formAddTransaction: {
        noc: 1,
        quoteid: null,
        parent:'SafeTravel Insurance Brokers',
        parent_name:'SafeTravel Insurance Brokers',
        parent_id:248,
        sbehalf: '',
        agent:'pallidis@icloud.com',
        agent_id:314,
        product: null,
        product_code: null,
        external_product: null,
        from:null,
        to:null,
        from_id:null,
        to_id:null,
        via:'',
        via_codes:'',
        email:'',
        start_date:'',
        end_date:'',
        address:'',
        phone:null,
        taxid:null,
        full_amount:null,
        clean_amount:null,
        tea:null,
        pfa:null,
        gender:[],
        full_name:[],
        dob:[]
      },
      formAddPartner: {
        company_name: '',
        level: 3,
        parent:'SafeTravel Insurance Brokers',
        parent_id:248,
        active: 'yes'
      },
      formPermission: {
        state: '',
        reason: '',
      },
      formEditPartner: {
        id: '',
        company_name: '',
        level: '',
        parent: '',
        active: '',
        parent_id: '',
      },
      formEditMember: {
        active: '',
        username: '',
        email: '',
        active: '',
        commission_amount: '',
        commission_operation: '',
        parent:'',
        organization_id: '',
      },
      formAddProducts: {
        parent:'SafeTravel Insurance Brokers',
        parent_id:248,
        product_code: null,
        products:[]
      },
      formAddMember: {
        username: '',
        password: '',
        password_confirm: '',
        email: '',
        active: 'yes',
        commission_amount: '0',
        commission_operation: 'Percentage',
        parent:'SafeTravel Insurance Brokers',
        organization_id:248,
        company_name:'SafeTravel Insurance Brokers',
        fullname: '',
        phone: '',
        address: '',
        zipcode: '',
        city: '',
        country: 'Greece',
      },
      formEditPassword: {
        password: '',
        repeat: '',
      },
      adminUser: false,
      provider: null,
      modalAction: true,
      isBusyPartners: true,
      isBusyMembers: true,
      isBusyMemberPartner : true,
      isBusyProducts : true,
      isBusyExpiredProducts : true,
      isBusyTransactions : true,
      isBusyCommi : true,
      isBusySanctions : true,
      isBusyDebts : true,
      isBusyPermissions : true,
      isBusyReserv : true,
      modes: ['multi', 'single', 'range'],
      perPage: 5,
      pageOptions: [5, 10, 25, 50, 100],
      totalRowsPartner: 1,
      totalRowsMember: 1,
      totalRowsMemberPartner: 1,
      totalRowsTransactions: 1,
      totalRowsProducts: 1,
      totalRowsExpProducts: 1,
      totalRowsCommi: 1,
      totalRowsSanctions: 1,
      totalRowsDebts: 1,
      totalRowsPermissions: 1,
      totalRowsReserv: 1,
      currentPageM: 1,
      currentPageP: 1,
      currentPagePM: 1,
      currentPagePr: 1,
      currentPageTr: 1,
      currentPageExp: 1,
      currentPageCommi: 1,
      currentPageSanctions: 1,
      currentPageDebts: 1,
      currentPagePermissions: 1,
      currentPageReserv: 1,
      sortByPartner: '',
      sortByMember: '',
      sortByMemberPartner: '',
      sortByProducts: '',
      sortByTransactions: '',
      sortByExpired: '',
      sortByCommi: '',
      sortBySanctions: '',
      sortByDebts: '',
      sortByPermissions: '',
      sortByReserv: '',
      sortDesc: true,
      sortDirectionP: 'desc',
      sortDirectionM: 'desc',
      sortDirectionPM: 'desc',
      sortDirectionPr: 'desc',
      sortDirectionTr: 'desc',
      sortDirectionExp: 'desc',
      sortDirectionCommi: 'desc',
      sortDirectionSanctions: 'desc',
      sortDirectionDebts: 'desc',
      sortDirectionPermissions: 'desc',
      sortDirectionReserv: 'desc',
      filterPartner: null,
      filterMember: null,
      filterPM: null,
      filterProducts: null,
      filterTransaction: null,
      filterExpProd: null,
      filterExpCommi: null,
      filterExpSanctions: null,
      filterExpDebts: null,
      filterPermissions: null,
      filterReserv: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fieldsPartners: [
        { key: 'partner_id', label: 'ID', sortable: true },
        { key: 'partner_company_name', label: 'Company Name', sortable: true },
        { key: 'partner_level', label: 'Level', sortable: true },
        { key: 'partner_parent_id', label: 'Parent ID', sortable: true },
        { key: 'partner_active', label: 'Active', sortable: true },
        { key: 'edit_partner', label: 'Edit'},
        { key: 'delete_partner', label: 'Delete'},
      ],
      fieldsMembers: [
        { key: 'member_id', label: 'memberID', sortable: true },
        { key: 'member_username', label: 'Username', sortable: true },
        { key: 'member_email', label: 'Email', sortable: true },
        { key: 'member_company_name', label: 'Company Name', sortable: true },
        { key: 'member_active', label: 'Active', sortable: true },
        { key: 'weekly_prod', label: 'Weekly Production', sortable: true },
        { key: 'monthly_prod', label: 'Monthly Production', sortable: true },
        { key: 'yearly_prod', label: 'Year Production', sortable: true },
        { key: 'total_prod', label: 'Total Production', sortable: true },
        { key: 'member_login', label: 'Login', sortable: true, tdClass: 'text-nowrap' },
        { key: 'edit_member', label: 'Edit'},
        { key: 'delete_member', label: 'Delete'},
      ],
      fieldsMemberPartner: [
        { key: 'mp_id', label: 'memberID', sortable: true },
        { key: 'mp_username', label: 'Username', sortable: true },
        { key: 'mp_email', label: 'Email', sortable: true },
        { key: 'mp_company_name', label: 'Company Name', sortable: true },
        { key: 'mp_parentcompany_name', label: 'Company Name', sortable: true },
        { key: 'unpaidDebts', label: 'Unpaid Debts', sortable: true },
        { key: 'mp_active', label: 'Active', sortable: true },
      ],
      fieldsProducts: [
        { key: 'company_name', label: 'Company Name', sortable: true },
        { key: 'username', label: 'Username', sortable: true },
        { key: 'email', label: 'Email', sortable: true },
        { key: 'provider', label: 'Provider', sortable: true },
        { key: 'product', label: 'Product', sortable: true },
        { key: 'delete_product', label: 'Delete'},
      ],
      fieldsTransactions: [
        { key: 'transactionID', label: 'transactionID', sortable: true },
        { key: 'email', label: 'Email', sortable: true },
        { key: 'sold_by', label: 'Sold By', sortable: true },
        { key: 'policy_type', label: 'Policy Type', sortable: true },
        { key: 'full_amount', label: 'Full Amount', sortable: true },
        { key: 'date', label: 'Date', sortable: true },
        { key: 'payment', label: 'Payment', sortable: true },
        { key: 'travellers', label: 'Travellers', sortable: true },
        { key: 'production', label: 'Production', sortable: true },
      ],
      fieldsExpProds: [
        { key: 'transactionID', label: 'transactionID', sortable: true },
        { key: 'email', label: 'Email', sortable: true },
        { key: 'sold_by', label: 'Sold By', sortable: true },
        { key: 'policyType', label: 'Policy Type', sortable: true },
        { key: 'full_amount', label: 'Full Amount', sortable: true },
        { key: 'end_date', label: 'End Date', sortable: true },
        { key: 'dateDiff', label: 'Expired Days', sortable: true },
      ],
      fieldsMembCommi: [
        { key: 'id', label: 'ID', sortable: true },
        { key: 'product_code', label: 'Product', sortable: true },
        { key: 'email', label: 'Email', sortable: true },
        { key: 'company_name', label: 'Company Name', sortable: true },
        { key: 'commission_amount', label: 'Commission Amount', sortable: true },
        { key: 'commission_operation', label: 'Commission Operation', sortable: true },
      ],
      fieldsSSanctions: [
        { key: 'id', label: 'ID', sortable: true },
        { key: 'wholeName', label: 'Full Name', sortable: true },
        { key: 'gender', label: 'Gender', sortable: true },
        { key: 'citizenship', label: 'Citizenship', sortable: true },
        { key: 'country', label: 'Country', sortable: true },
        { key: 'city', label: 'City', sortable: true },
        { key: 'birthdate', label: 'DOB', sortable: true },
      ],
      fieldsDebts: [
        { key: 'quote_id', label: 'Quote ID', sortable: true },
        { key: 'partner', label: 'Partner', sortable: true },
        { key: 'customer_name', label: 'Customer Name', sortable: true },
        { key: 'date_of_issue', label: 'Issue Date', sortable: true },
        { key: 'date_of_trip_start', label: 'Start Date', sortable: true },
        { key: 'date_of_trip_end', label: 'End Date', sortable: true },
        { key: 'payable', label: 'Payable', sortable: true },
        { key: 'gross', label: 'Gross', sortable: true },
        { key: 'net', label: 'Net', sortable: true },
        { key: 'commission', label: 'Commission', sortable: true },
      ],
      fieldsPermissions: [
        { key: 'memberID', label: 'Member ID', sortable: true },
        { key: 'username', label: 'Username', sortable: true },
        { key: 'email', label: 'Email', sortable: true },
        { key: 'company_name', label: 'Company', sortable: true },
        { key: 'partner_company', label: 'Partner', sortable: true },
        { key: 'active', label: 'Active', sortable: true },
        { key: 'permission_name', label: 'Permission', sortable: true },
        { key: 'state', label: 'State', sortable: true },
        { key: 'created_at', label: 'Created', sortable: true },
      ],
      fieldsReserv: [
        { key: 'transactionID', label: 'Transaction ID', sortable: true },
        { key: 'state', label: 'State', sortable: true },
        { key: 'email', label: 'Email', sortable: true },
        { key: 'sold_by', label: 'Sold By', sortable: true },
        { key: 'policyType', label: 'Policy Type', sortable: true },
        { key: 'full_amount', label: 'Full Amount', sortable: true },
        { key: 'clean_amount', label: 'Clean Amount', sortable: true },
        { key: 'state', label: 'State', sortable: true },
        { key: 'r_timestamp', label: 'Date', sortable: true },
        { key: 'order_code', label: 'Checkout URL', sortable: true },
        { key: 'on_behalf_of', label: 'Cancel', sortable: true },
      ],
      /* eslint-disable global-require */
      optionsPartners: [],
      allProducts: [],
      optionsMembers: [],
      optionsAgents: [
        {
          value:314,
          text:'pallidis@icloud.com'
        },
        {
          value:396,
          text:'polina@safetravel.today'
        },
        {
          value:510,
          text:'sl@thegreekvillas.gr'
        },
        {
          value:10024,
          text:'it@safetravel.today'
        },
      ],
      genders:[
        'Mr.',
        'Mrs.'
      ],
      itemsPartners: [],
      itemsMembers: [],
      itemsMemberPartner: [],
      itemsProducts: [],
      itemsExpiredProducts: [],
      itemsMembersCommi: [],
      itemsSanctions: [],
      itemsDebts: [],
      itemsPermissions: [],
      itemsReserv: [],
      itemsTransactions: [],
      /* eslint-disable global-require */
      status: [{
        1: 'Current', 2: 'Professional', 3: 'Rejected', 4: 'Resigned', 5: 'Applied',
      },
      {
        1: 'light-primary', 2: 'light-success', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
      }],
      codeKitchenSink,
      selected: [],
      codeRowSelectSupport,
      dismissSecs: 2,
      dismissCountDown: null,
      showDismissibleAlert: false,     
      token_id,
      cookie
    }
  },
  computed: {
    sortOptionsPartners() {
      // Create an options list from our fields
      return this.fieldsPartners
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    sortOptionsMembers() {
      // Create an options list from our fields
      return this.fieldsMembers
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    sortOptionsMemberPartner() {
      // Create an options list from our fields
      return this.fieldsMemberPartner
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    sortOptionsProducts() {
      // Create an options list from our fields
      return this.fieldsProducts
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    sortOptionsTransactions() {
      // Create an options list from our fields
      return this.fieldsTransactions
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    sortOptionsExp() {
      // Create an options list from our fields
      return this.fieldsExpProds
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    sortOptionsCommi() {
      // Create an options list from our fields
      return this.fieldsMembCommi
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    sortOptionsSanctions() {
      // Create an options list from our fields
      return this.fieldsSSanctions
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    sortOptionsDebts() {
      // Create an options list from our fields
      return this.fieldsDebts
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    sortOptionsPermissions() {
      // Create an options list from our fields
      return this.fieldsPermissions
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    sortOptionsReserv() {
      // Create an options list from our fields
      return this.fieldsReserv
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  async created() {
    await this.checkAdminPanel()
    await this.get_partners()
    await this.get_members()
    await this.get_member_partner()
    await this.get_products()
    await this.get_transactions()
    await this.getAllProducts()
    await this.get_expired_products()
    await this.member_commissions()
    await this.get_sanctions()
    await this.get_permissions()
    await this.get_reservations()
    await this.unpaid_debts()
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0,0);
    },
    async getAllProducts() {
      const url= {
        prod: 'https://api-quote.safetravel.today/v2/app/get_all_products'
      };
      const data = {
        token: this.token_id,
        jwt: this.cookie
      }
      try {
        await fetch(url.prod, {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        })
        .then((response)=>response.json())
        .then((res)=> {
          if (res) {
            if (res === 'not_valid') {
              window.location.href = '/logout';
              return;
            }
            res.forEach((r)=> {
              this.allProducts.push({
                value:  r?.product_code ?? null, 
                text: r?.product_code + ' (' + r?.product_name + ') ' + '-- ' + r?.provider ?? null, 
              })
            })
          }
        })
      } catch (error) {
        // TypeError: Failed to fetch
        console.warn('There was an error getting products', error);
      }
    },
    async addTransaction(event) {
      event.preventDefault()
      this.formAddTransaction['token']=this.token_id
      this.formAddTransaction['jwt']=this.cookie
      if (this.formAddTransaction.via == '') {
        this.formAddTransaction.via_codes = `${String(this.formAddTransaction.from_id)}-${String(this.formAddTransaction.to_id)}` 
      } else {
        const v=[]
        this.formAddTransaction.via.forEach((e)=> {
          v.push(String(e.value));
          const vv=v.join('-');
          this.formAddTransaction.via_codes = `${String(this.formAddTransaction.from_id)}-${vv}-${String(this.formAddTransaction.to_id)}`
        })
      }
      const url= {
        prod: 'https://api-quote.safetravel.today/v2/app/add_transaction'
      };
      try {
        await fetch(url.prod, {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(this.formAddTransaction)
        })
        .then((response)=>response.json())
        .then((res)=> {
          if (res === 'not_valid') {
            window.location.href = '/logout';
            return;
          }
          if (res) {
            this.modaladdedTr=true
            setTimeout(() => {
              location.reload()
            }, 2000);
          } else {
            this.duplicate='Quote id exist'
          }
        })
      } catch (error) {
        // TypeError: Failed to fetch
        console.warn('There was an error adding transaction', error);
      }
    },
    async addPMemberForm(event) {
      event.preventDefault()
      this.formAddMember['token']=this.token_id
      this.formAddMember['jwt']=this.cookie
      if (this.formAddMember.password !== this.formAddMember.password_confirm) {
        this.passMatchMember = 'Passwords do not match'
        return
      } else {
        this.passMatchMember = ''
      }
      const url= {
        prod: 'https://api-quote.safetravel.today/v2/app/add_member'
      };
      try {
        await fetch(url.prod, {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(this.formAddMember)
        })
        .then((response)=>response.json())
        .then((res)=> {
          if (res === 'not_valid') {
            window.location.href = '/logout';
            return;
          }
          if (res) {
            this.duplicate=''
            const msg = `Member has been added`
            this.showAlert(msg);
            this.scrollToTop()
          } else {
            this.duplicate='Email already in use'
          }
        })
      } catch (error) {
        // TypeError: Failed to fetch
        console.warn('There was an error adding member', error);
      }
    },
    async addPProductForm(event) {
      event.preventDefault()
      this.formAddProducts['token']=this.token_id
      this.formAddProducts['jwt']=this.cookie
      const url= {
        prod: 'https://api-quote.safetravel.today/v2/app/add_products'
      };
      try {
        await fetch(url.prod, {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(this.formAddProducts)
        })
        .then((response)=>response.json())
        .then((res)=> {
          if (res === 'not_valid') {
            window.location.href = '/logout';
            return;
          }
          if (res) {
            this.duplicate=''
            const msg = `Product(s) has been added`
            this.showAlert(msg);
            this.scrollToTop()
          } else {
            this.duplicate='Product(s) exists'
          }
        })
      } catch (error) {
        // TypeError: Failed to fetch
        console.warn('There was an error adding product', error);
      }
    },
    async addPartnerForm(event) {
      event.preventDefault()
      this.formAddPartner['token']=this.token_id
      this.formAddPartner['jwt']=this.cookie
      const url= {
        prod: 'https://api-quote.safetravel.today/v2/app/add_partner'
      };
      try {
        await fetch(url.prod, {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(this.formAddPartner)
        })
        .then((response)=>response.json())
        .then((res)=> {
          if (res === 'not_valid') {
            window.location.href = '/logout';
            return;
          }
          if (res) {
            this.duplicate=''
            const msg = `Partner has been added`
            this.showAlert(msg);
            this.scrollToTop()
          } else {
            this.duplicate='Company name exists'
          }
        })
      } catch (error) {
        // TypeError: Failed to fetch
        console.warn('There was an error adding partner', error);
      }
    },
    updateProductCode(elem) {
      this.formAddTransaction.product_code = elem.value
    },
    updateResidence(elem) {
      this.formAddTransaction.from_id = Number(elem.value)
    },
    updateDestination(elem) {
      this.formAddTransaction.to_id = Number(elem.value)
    },
    updateAgentID(elem) {
      this.formAddTransaction.agent_id =  Number(elem.value)
    },
    updateAgent(elem) {
      this.formAddTransaction.parent_id =  Number(elem.value)
      const url= {
        prod: 'https://api-quote.safetravel.today/v2/app/update_agent'
      };
      const data = {
        token: this.token_id,
        organization_id: elem.value,
      }
      try {
        fetch(url.prod, {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        })
        .then((response)=>response.json())
        .then((res)=> {
          this.optionsAgents=[]
          if(res.length < 1 || res == undefined) {
            this.formAddTransaction.agent = ''
            this.formAddTransaction.agent_id = ''
          } else {
            res.forEach((r)=> {
              this.optionsAgents.push({
                value:  Number(r?.memberID) ?? null, 
                text: r?.email ?? null, 
              })
              this.formAddTransaction.agent = this.optionsAgents[0].text
              this.formAddTransaction.agent_id =  Number(this.optionsAgents[0].value)
            })
          }
        })
      } catch (error) {
        // TypeError: Failed to fetch
        console.warn('Couldnt update agent', error);
      }
    },
    updateMember(elem) {
      this.formAddProducts.parent_id = elem.value
    },
    updateOrg(elem) {
      this.formAddMember.organization_id = elem.value
      this.formAddMember.company_name = elem.text
    },
    updatePrCode(elem) {
      this.formAddProducts.products=[]
      elem.forEach((e)=> {
        this.formAddProducts.products.push(e.value)
      })
    },
    updateParent(elem) {
      this.formAddPartner.parent_id = elem.value
    },
    saveParentID(elem) {
      this.formEditPartner.parent_id = elem.value
    },
    saveMParentID(elem) {
      this.formEditMember.organization_id = elem.value
      this.formEditMember.parent = elem.text
    },
    getMemberDts(data) {
      this.optionsMembers.forEach((p)=> {
        if (p.value === Number(data.organization_id)) {
          this.formEditMember.parent = p.text
          this.formEditMember.organization_id = p.value
        }
      })
      this.formEditMember.id = Number(data.member_id)
      this.formEditMember.username = data.member_username
      this.formEditMember.email = data.member_email
      this.formEditMember.active = data.member_active
      this.formEditMember.commission_amount = String(data.commission_amount)
      this.formEditMember.commission_operation = data.commission_operation
    },
    getParentCompany(data, parentid) {
      this.optionsPartners.forEach((p)=> {
        if (p.value === Number(parentid)) {
          this.formEditPartner.parent = p.text
          this.formEditPartner.parent_id = p.value
        }
      })
      this.formEditPartner.id = Number(data.partner_id)
      this.formEditPartner.company_name = data.partner_company_name
      this.formEditPartner.level = Number(data.partner_level)
      this.formEditPartner.active = data.partner_active
    },
    deletePartner(partnerID, partner) {
      const confirm_delete = confirm("Are you sure you want to delete partner " + partner + "?");
      if (confirm_delete) {
        const url= {
          prod: 'https://api-quote.safetravel.today/v2/app/delete_partner'
        };
        const data = {
          token: this.token_id,
          partnerID: partnerID,
          company_name: partner
        }
        try {
          fetch(url.prod, {
              method: 'post',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(data)
          })
          .then((response)=>response.json())
          .then((res)=> {
            if (res) {
              const msg = `Partner ${partner} has been deleted`
              this.showAlert(msg);
              this.scrollToTop()
            }
          })
        } catch (error) {
          // TypeError: Failed to fetch
          console.warn('There was an error deleting partner', error);
        }
      }
    },
    deleteMember(memberID, member) {
      const confirm_delete = confirm("Are you sure you want to delete member " + member + "?");
      if (confirm_delete) {
        const url= {
          prod: 'https://api-quote.safetravel.today/v2/app/delete_member'
        };
        const data = {
          token: this.token_id,
          memberID: memberID,
          email: member_email
        }
        try {
          fetch(url.prod, {
              method: 'post',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(data)
          })
          .then((response)=>response.json())
          .then((res)=> {
            if (res) {
              const msg = `Member ${member} has been deleted`
              this.showAlert(msg);
              this.scrollToTop()
            }
          })
        } catch (error) {
          // TypeError: Failed to fetch
          console.warn('There was an error deleting member', error);
        }
      }
    },
    deleteProduct(id, code, name) {
      const confirm_delete = confirm("Are you sure you want to delete the product: " + code + " from " + name + " ?");
      if (confirm_delete) {
        const url= {
          prod: 'https://api-quote.safetravel.today/v2/app/delete_product'
        };
        const data = {
          token: this.token_id,
          id: id,
          product_code: code
        }
        try {
          fetch(url.prod, {
              method: 'post',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(data)
          })
          .then((response)=>response.json())
          .then((res)=> {
            if (res) {
              const msg = `Product ${code} has been deleted from ${name}`
              this.showAlert(msg);
              this.scrollToTop()
            }
          })
        } catch (error) {
          // TypeError: Failed to fetch
          console.warn('There was an error deleting product', error);
        }

      }
    },
    async checkAdminPanel() {
      if (localStorage.getItem("adminPanel") !== null) {
        this.adminUser=true
        this.modalAction=false
      }
    },
    async updateTrState(tr_id,tr_val,tr_title) {
      const url= {
        local: 'http://localhost:8008/v2/app/update_transactionstate',
        prod: 'https://api-quote.safetravel.today/v2/app/update_transactionstate'
      };
      const data = {
        token: this.token_id,
        jwt: this.cookie,
        transaction_state: tr_val,
        transactionID: Number(tr_id)
      }
      try {
        await fetch(url.prod, {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        })
        .then((response)=>response.json())
        .then((res)=> {
          if (res === 'not_valid') {
            window.location.href = '/logout';
            return;
          }
          this.alertMsgNR = `Transaction state has been updated`
          this.alertNoReload=true;
          // change dropdown text!
          document.querySelector(`#drop__${tr_id} > button`).textContent=tr_title
        })
      } catch (error) {
        // TypeError: Failed to fetch
        console.warn('There was an error updating transaction state', error);
      }
    },
    async checkTravellers(tr) {
      const url= {
        prod: 'https://api-quote.safetravel.today/v2/app/check_travellers'
      };
      const data = {
        token: this.token_id,
        transactionID: tr,
        jwt: this.cookie
      }
      try {
        await fetch(url.prod, {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        })
        .then((response)=>response.json())
        .then((res)=> {
          if (res === 'not_valid') {
            window.location.href = '/logout';
            return;
          }
          this.tr_title_trav = tr          
          this.modalchTravellers=true
          this.travellersArr=res
        })
        } catch (error) {
          // TypeError: Failed to fetch
          console.warn('There was an error checking travellers', error);
        }
    },
    async generateProduction(production) {
      this.isButtonDisabled = true;
      const url= {
        prod: 'https://api-quote.safetravel.today/v2/app/get_production'
      };
      const data = {
        token: this.token_id,
        sold_by: production,
        jwt: this.cookie
      }
      try {
        const response = await fetch(url.prod, {
          method: 'post',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(data)
        });
        const res = await response.json();
        if (res === 'not_valid') {
          window.location.href = '/logout';
          return;
        }
        const updatedRes = res.map(item=> {
          return {
            ...item,
            policyType: this.products[item.policyType] || item.policyType
          }
        })
        // download excel
        this.downloadExcel(updatedRes, 'Production.xlsx');
      } catch (err) {
        console.warn('There was an error downloading excel', err);
      } finally {
        this.isButtonDisabled = false;
      }
    },
    cnlbtnF(transactionID) {
      const url= {
        prod: 'https://api-quote.safetravel.today/v2/app/cancel_state'
      };
      const data = {
        token: this.token_id,
        jwt: this.cookie,
        transactionID,
        state:"cancelled"
      }
      try {
        fetch(url.prod, {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        })
        .then((response)=>response.json())
        .then((res)=> {
          if (res) {
            setTimeout(function() {
              document.querySelector("#checkoutlink_"+transactionID).remove();
            },50)
          }
        })
        } catch (error) {
          // TypeError: Failed to fetch
          console.warn('There was an error changing state at transactions_unpaid', error);
        }
    },
    async checkIndividuals(username,mbrToken) {
      const url= {
        prod: 'https://api-quote.safetravel.today/v2/app/financial/export/individual'
      };
      const data = {
        env: "production",
        token: this.token_id,
        jwt: this.cookie,
        from: "2023-01-01 00:00:00",
        to: "2024-12-30 00:00:00",
        member: mbrToken
      }
      try {
        await fetch(url.prod, {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        })
        .then(response=>response.json())
        .then(res=> {
          if (res === 'not_valid') {
            window.location.href = '/logout';
            return;
          }
          this.q__id = username          
          this.modalchIndividuals=true
          if(res?.export?.length > 0) {
            this.IndividualsArr = res.export
          } else {
            this.IndividualsArr = null;
          }
        })
        } catch (error) {
          // TypeError: Failed to fetch
          console.warn('There was an error getting individual debts', error);
        }
    },
    async onEditMemberPassword(id, event) {
      event.preventDefault()
      this.formEditPassword['id']=id
      this.formEditPassword['token']=this.token_id
      this.formEditPassword['jwt']=this.cookie
      if (this.formEditPassword.password !== this.formEditPassword.repeat) {
        this.passMatch = 'Passwords do not match'
        return
      }
      this.passMatch=''
      const url= {
        prod: 'https://api-quote.safetravel.today/v2/app/update_member_pass'
      };
      try {
        await fetch(url.prod, {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(this.formEditPassword)
        })
        .then((response)=>response.json())
        .then((res)=> {
          if (res === 'not_valid') {
            window.location.href = '/logout';
            return;
          }
          if (res) {
            this.modalmemberEdit=true
            setTimeout(() => {
              location.reload()
            }, 2000);
          }
        })
      } catch (error) {
        // TypeError: Failed to fetch
        console.warn('There was an error updating member password', error);
      }
    },
    async onEditMember(event) {
      event.preventDefault()
      this.formEditMember['token']=this.token_id
      this.formEditMember['jwt']=this.cookie
      const url= {
        prod: 'https://api-quote.safetravel.today/v2/app/edit_member'
      };
      try {
        await fetch(url.prod, {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(this.formEditMember)
        })
        .then((response)=>response.json())
        .then((res)=> {
          if (res) {
            if (res === 'not_valid') {
              window.location.href = '/logout';
              return;
            }
            this.duplicate=''
            this.modalmemberEdit=true
            setTimeout(() => {
              location.reload()
            }, 2000);
          } else {
            this.duplicate='Email already in use'
          }
        })
      } catch (error) {
        // TypeError: Failed to fetch
        console.warn('There was an error editing member', error);
      }
    },
    async onEditPartner(event) {
      event.preventDefault()
      this.formEditPartner['token']=this.token_id
      this.formEditPartner['jwt']=this.cookie
      const url= {
        prod: 'https://api-quote.safetravel.today/v2/app/edit_partner'
      };
      try {
        await fetch(url.prod, {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(this.formEditPartner)
        })
        .then((response)=>response.json())
        .then((res)=> {
          if (res === 'not_valid') {
            window.location.href = '/logout';
            return;
          }
          if (res) {
            this.duplicate=''
            this.modalpartnerEdit=true
            setTimeout(() => {
              location.reload()
            }, 2000);
          } else {
            this.duplicate='Company name exists'
          }
        })
      } catch (error) {
        // TypeError: Failed to fetch
        console.warn('There was an error editing partner', error);
      }
    },
    async onSubmit(event) {
      event.preventDefault()
      const crd = this.formJWT
      const xacctok = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2NzA3ODM4NTIsImV4cCI6MTY3MDc5MTA1MiwianRpIjoibTNPR01oVmtRUk9mbnIyY3BrWHBOQT09IiwiaXNzIjoiYXBpLXF1b3RlLnNhZmV0cmF2ZWwudG9kYXkiLCJzdWIiOiJwYWxsaWRpc0BpY2xvdWQuY29tIiwibmFtZSI6IlZhc2lsaXMgUGFsbGlkaXMgSW5zdXJhbmNlIEJyb2tlcnMiLCJzZXJpYWwiOiIxMGU1MzM5MDc4NWI0NWQ0Y2U3YzM4ZWQ2MDMyNDI2YiIsInNjb3BlIjpbImFwcC50cmF2ZWwtaW5zdXJhbmNlLnF1b3RlIiwiYXBwLnRyYXZlbC1pbnN1cmFuY2UucHVyY2hhc2UiLCJhcHAudHJhdmVsLWluc3VyYW5jZS5wYXltZW50Il19._9UyzD6sYtj6Cco_Yw8V5M8Vfu32nh7fCE0l35-mmfI";
      try {
        await fetch('https://api-quote.safetravel.today/v2/login', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${xacctok}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(crd)
        })
        .then((response)=> {
          return response.json();
        })
        .then((res)=> {
          if (res.jwt) {
            const decoded = jwt.decode(res.jwt, { complete: true });
            if(decoded.payload.name==="Vasilis Pallidis Insurance Brokers") {
              this.adminUser=true
              this.modalAction=false
              localStorage.setItem('adminPanel', true)
            } else {
              this.formJWT.username=""
              this.formJWT.password=""
            }
          } else {
            this.formJWT.username=""
            this.formJWT.password=""
          }
        })
      } catch(e) {
         // TypeError: Failed to fetch
         console.warn('There was an error on fetch login', error);
      }
    },
    async get_partners() {
      const url= {
        prod: 'https://api-quote.safetravel.today/v2/app/get_partners'
      };
      const data = {
        token: this.token_id,
        jwt: this.cookie
      }
      try {
          await fetch(url.prod, {
              method: 'post',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(data)
          })
          .then((response)=>response.json())
          .then((res)=> {
            if (res === 'not_valid') {
              window.location.href = '/logout';
              return;
            }
            res.forEach((r)=> {
              this.itemsPartners.push({
                partner_id: r?.id ?? null,
                partner_company_name: r?.company_name ?? null,
                partner_level: r?.level ?? null,
                partner_parent_id: r?.parent_id ?? null,
                partner_active: r?.active ?? null,
              })
              this.optionsPartners.push({
                value:  Number(r?.id) ?? null, 
                text: r?.company_name ?? null, 
              })
              this.optionsMembers.push({
                value:  Number(r?.id) ?? null, 
                text: r?.company_name ?? null, 
              })
            })
          })
          .then(()=> {
            this.totalRowsPartner = this.itemsPartners.length;
            this.isBusyPartners = false
            this.sortByPartner = 'partner_id';
          })
      } catch (error) {
        // TypeError: Failed to fetch
        console.warn('There was an error fetching partners', error);
      }
    },
    async get_members() {
      const url= {
        prod: 'https://api-quote.safetravel.today/v2/app/get_members'
      };
        const data = {
          token: this.token_id,
          jwt: this.cookie
        }
        try {
            await fetch(url.prod, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data)
            })
            .then((response)=>response.json())
            .then((res)=> {
              if (res === 'not_valid') {
                window.location.href = '/logout';
                return;
              }
              res.forEach((r)=> {
                this.itemsMembers.push({
                  member_id: r?.memberID ?? null,
                  member_username: r?.username ?? null,
                  member_email: r?.email ?? null,
                  member_company_name: r?.company_name ?? null,
                  member_active: r?.active ?? null,
                  weekly_prod: (r?.prod_weekly && r?.prod_weekly > '0') ? parseFloat(r?.prod_weekly).toFixed(2) : 0,
                  monthly_prod: (r?.prod_monthly && r?.prod_monthly > '0') ? parseFloat(r?.prod_monthly).toFixed(2) : 0,
                  yearly_prod: (r?.prod_yearly && r?.prod_yearly > '0') ? parseFloat(r?.prod_yearly).toFixed(2) : 0,
                  total_prod: (r?.total_production && r?.total_production > '0') ? parseFloat(r?.total_production).toFixed(2) : null,
                  member_login: r?.last_login ?? null,
                  commission_amount: String(r?.commission_amount) ?? 0,
                  commission_operation: r?.commission_operation ?? null,
                  organization_id: r?.organization_id ?? null,
                })
              })
            })
            .then(()=> {
              this.totalRowsMember = this.itemsMembers.length;
              this.isBusyMembers = false
              this.sortByMember = 'member_id';
            })
        } catch (error) {
          // TypeError: Failed to fetch
          console.warn('There was an error fetching members', error);
        }
    },
    async get_member_partner() {
      const url= {
        prod: 'https://api-quote.safetravel.today/v2/app/get_member_partner'
      };
        const data = {
          token: this.token_id,
          jwt: this.cookie
        }
        try {
            await fetch(url.prod, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data)
            })
            .then((response)=>response.json())
            .then((res)=> {
              if (res === 'not_valid') {
                window.location.href = '/logout';
                return;
              }
              res.forEach(r=> {
                this.itemsMemberPartner.push({
                  mp_id: r?.memberID ?? null,
                  mp_username: r?.username ?? null,
                  mp_email: r?.email ?? null,
                  mp_company_name: r?.company_name ?? null,
                  mp_parentcompany_name: r?.parent_company ?? null,
                  mp_active: r?.active ?? null,
                  mbrToken: r?.token ?? null,
                })
              })
            })
            .then(()=> {
              this.totalRowsMemberPartner = this.itemsMemberPartner.length;
              this.isBusyMemberPartner = false
              this.sortByMemberPartner = 'mp_id';
            })
        } catch (error) {
          // TypeError: Failed to fetch
          console.warn('There was an error fetching members with partner', error);
        }
    },
    async get_products() {
      const url= {
        prod: 'https://api-quote.safetravel.today/v2/app/get_products'
      };
      const data = {
        token: this.token_id,
        jwt: this.cookie
      }
      try {
          await fetch(url.prod, {
              method: 'post',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(data)
          })
          .then((response)=>response.json())
          .then((res)=> {
            if (res === 'not_valid') {
              window.location.href = '/logout';
              return;
            }
            res.forEach(r=> {
              // check provider
              let prod='';
              let provider='';
              if (r.product_code) {
                prod = r.product_code.replace(/^\D+/g, '');
                if (prod[0] == '9') {
                  provider = 'collinson';
                } else if (prod[0] == '6') {
                  provider = 'mondial';
                } else if (prod[0] == '8') {
                  provider = 'kemter';
                } else if (prod[0] == '4') {
                  provider = 'battleface';
                } else {
                  provider = "";
                }
              } else {
                provider='';
              }
              this.itemsProducts.push({
                id: r?.id ?? null,
                company_name: r?.company_name ?? null,
                username: r?.username ?? null,
                email: r?.email ?? null,
                product: r?.product_code ?? null,
                provider: provider ?? null,
              })
            })
          })
          .then(()=> {
            this.totalRowsProducts = this.itemsProducts.length;
            this.isBusyProducts = false
            this.sortByProducts = 'company_name';
          })
      } catch (error) {
        // TypeError: Failed to fetch
        console.warn('There was an error fetching products', error);
      }
    },
    async get_transactions() {
      const url= {
        prod: 'https://api-quote.safetravel.today/v2/app/get_transactions'
      };
      const data = {
        token: this.token_id,
        jwt: this.cookie
      }
      try {
        await fetch(url.prod, {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        })
        .then((response)=>response.json())
        .then((res)=> {
          if (res === 'not_valid') {
            window.location.href = '/logout';
            return;
          }
          res.forEach((r)=> {
            this.itemsTransactions.push({
              transactionID: r?.transactionID,
              email: r?.email,
              sold_by: r?.members_email,
              policy_type: r?.policyType,
              full_amount: r?.full_amount,
              transaction_state: r?.transaction_state,
              date: r?.r_timestamp,
              production: r?.sold_by,
            })
          })
        })
        .then(()=> {
          this.totalRowsTransactions = this.itemsTransactions.length;
          this.isBusyTransactions = false
          this.sortByTransactions = 'date';
        })
      } catch (error) {
        // TypeError: Failed to fetch
        console.warn('There was an error fetching transactions', error);
      }
    },
    async get_expired_products() {
      const url= {
        prod: 'https://api-quote.safetravel.today/v2/app/expired_products'
      };
      const data = {
        token: this.token_id,
        jwt: this.cookie
      }
      try {
          await fetch(url.prod, {
              method: 'post',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(data)
          })
          .then((response)=>response.json())
          .then((res)=> {
            if (res === 'not_valid') {
              window.location.href = '/logout';
              return;
            }
            res.forEach(r=> {
              this.itemsExpiredProducts.push({
                transactionID: r?.transactionID ?? null,
                email: r?.email ?? null,
                end_date: r?.end_date ?? null,
                full_amount: r?.full_amount ?? null,
                policyType: r?.policyType ?? null,
                sold_by: r?.sold_by ?? null,
                dateDiff: r?.dateDiff ?? null,
              })
            })
          })
          .then(()=> {
            this.totalRowsExpProducts = this.itemsExpiredProducts.length;
            this.isBusyExpiredProducts = false
            this.sortByExpired = 'transactionID';
          })
      } catch (error) {
        // TypeError: Failed to fetch
        console.warn('There was an error fetching expired products', error);
      }
    },
    async member_commissions() {
      const url= {
        prod: 'https://api-quote.safetravel.today/v2/app/member_commissions'
      };
      const data = {
        token: this.token_id,
        jwt: this.cookie
      }
      try {
          await fetch(url.prod, {
              method: 'post',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(data)
          })
          .then((response)=>response.json())
          .then((res)=> {
            if (res === 'not_valid') {
              window.location.href = '/logout';
              return;
            }
            res.forEach(r=> {
              this.itemsMembersCommi.push({
                id: r?.id ?? null,
                email: r?.email ?? null,
                company_name: r?.company_name ?? null,
                product_code: products[r?.product_code] ?? null,
                commission_amount: r?.commission_amount ?? null,
                commission_operation: r?.commission_operation ?? null,
              })
            })
          })
          .then(()=> {
            this.totalRowsCommi = this.itemsMembersCommi.length;
            this.isBusyCommi = false
            this.sortByCommi = 'id';
          })
      } catch (error) {
        // TypeError: Failed to fetch
        console.warn('There was an error fetching member commissions', error);
      }
    },
    async get_sanctions() {
      const url= {
        prod: 'https://api-quote.safetravel.today/v2/app/get_sanctions'
      };
      const data = {
        token: this.token_id,
        jwt: this.cookie
      }
      try {
          await fetch(url.prod, {
              method: 'post',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(data)
          })
          .then((response)=>response.json())
          .then((res)=> {
            if (res === 'not_valid') {
              window.location.href = '/logout';
              return;
            }
            res.forEach(r=> {
              this.itemsSanctions.push({
                id: r?.id ?? null,
                wholeName: r?.wholeName ?? '-',
                gender: r?.gender ?? '-',
                citizenship: r?.citizenship ?? '-',
                country: r?.country ?? '-',
                city: r?.city ?? '-',
                birthdate: r?.birthdate ?? '-',
              })
            })
          })
          .then(()=> {
            this.totalRowsSanctions = this.itemsSanctions.length;
            this.isBusySanctions = false
            this.sortBySanctions = 'id';
          })
      } catch (error) {
        // TypeError: Failed to fetch
        console.warn('There was an error fetching sanctions', error);
      }
    },
    async unpaid_debts() {
      const url= {
        prod: 'https://api-quote.safetravel.today/v2/app/get_partners_token',
        prod_export:'https://api-quote.safetravel.today/v2/app/financial/export/company'
      };
      const data = {
        token: this.token_id,
        jwt: this.cookie
      }
      try {
        await fetch(url.prod, {
          method: 'post',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(data)
        })
        .then((response)=>response.json())
        .then(res=>{
          this.dataExport(res,url.prod_export,'2024-01-01 00:00:00','2024-05-30 00:00:00')
          this.dataExport(res,url.prod_export,'2023-06-01 00:00:00','2023-12-30 00:00:00')
          this.dataExport(res,url.prod_export,'2023-01-01 00:00:00','2023-05-30 00:00:00')
        })
      } catch (error) {
        // TypeError: Failed to fetch
        console.warn('There was an error fetching unpaid debts', error);
      }
    },
    async dataExport(res,fetchUrl,from,to) {
      const companies=[];
      for (const r of res) {
        companies.push(r?.token)
      }
      const data_export = {
        env: "production",
        token: this.token_id,
        jwt: this.cookie,
        from,
        to,
        company: companies,
      }
      try {
        fetch(fetchUrl, {
          method: 'post',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(data_export)
        })
        .then((respon)=>respon.json())
        .then((resp)=> {
          if (resp === 'not_valid') {
            window.location.href = '/logout';
            return;
          }
          resp.export.forEach(r=> {
            this.itemsDebts.push({
              commission: r?.commission ?? null,
              customer_name: r?.customer_name ?? '-',
              date_of_issue: r?.date_of_issue ?? '-',
              date_of_trip_start: r?.date_of_trip_start ?? '-',
              date_of_trip_end: r?.date_of_trip_end ?? '-',
              gross: r?.gross ?? '-',
              net: r?.net ?? '-',
              partner: r?.partner ?? '-',
              payable: r?.payable ?? '-',
              quote_id: r?.quote_id ?? '-',
            })
          })
        })
        .then(()=> {
          this.totalRowsDebts = this.itemsDebts.length;
          this.isBusyDebts = false
          this.sortByDebts = 'partner';
        })
      } catch (error) {
        // TypeError: Failed to fetch
        console.warn('There was an error fetching financial export company', error);
      }
    },
    async get_permissions() {
      const url= {
        prod: 'https://api-quote.safetravel.today/v2/app/get_permissions',
      };
      const data = {
        token: this.token_id,
        jwt: this.cookie
      }
      try {
        await fetch(url.prod, {
          method: 'post',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(data)
        })
        .then((response)=>response.json())
        .then(res=>{
          res.forEach(r=> {
            this.itemsPermissions.push({
              idper: r?.idper ?? null,
              memberID: r?.memberID ?? null,
              username: r?.username ?? null,
              email: r?.email ?? null,
              company_name: r?.company_name ?? null,
              partner_company: r?.partner_company ?? null,
              active: r?.active ?? null,
              permission_name: r?.permission_name.slice(21) ?? null,
              state: r?.state ?? null,
              created_at: r?.created_at ?? null,
            })
          })
        })
        .then(()=> {
          this.totalRowsPermissions = this.itemsPermissions.length;
          this.isBusyPermissions = false
          this.sortByPermissions = 'created_at';
        })
      } catch (error) {
        // TypeError: Failed to fetch
        console.warn('There was an error fetching permissions', error);
      }
    },
    async get_reservations() {
      const url= {
        prod: 'https://api-quote.safetravel.today/v2/app/get_reservations',
      };
      const data = {
        token: this.token_id,
        jwt: this.cookie
      }
      try {
        await fetch(url.prod, {
          method: 'post',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(data)
        })
        .then((response)=>response.json())
        .then(res=>{
          res.forEach(r=> {
            this.itemsReserv.push({
              transactionID: r?.transactionID ?? null,
              state: r?.state ?? null,
              sold_by: r?.members_email ?? null,
              policyType: r?.policyType ?? null,
              order_code: r?.order_code ?? null,
              on_behalf_of: r?.members_email ?? null,
              email: r?.email ?? null,
              full_amount: r?.full_amount ?? null,
              email: r?.email ?? null,
              clean_amount: r?.clean_amount ?? null,
              r_timestamp: r?.r_timestamp ?? null,
            })
          })
        })
        .then(()=> {
          this.totalRowsReserv = this.itemsReserv.length;
          this.isBusyReserv = false
          this.sortByReserv = 'r_timestamp';
        })
      } catch (error) {
        // TypeError: Failed to fetch
        console.warn('There was an error fetching reservations', error);
      }
    },
    async editPermission(member_id, permission_name, form) {
      const url= {
        prod: 'https://api-quote.safetravel.today/v2/app/edit_permission',
      };
      const data = {
        token:this.token_id,
        jwt:this.cookie,
        member_id: Number(member_id),
        permission_name,
        state: form.state,
        reason: form.reason
      }
      try {
        await fetch(url.prod, {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        })
        .then((response)=>response.json())
        .then((res)=> {
          if (res) {
            if (res === 'not_valid') {
              window.location.href = '/logout';
              return;
            }
            // reset reason
            this.formPermission.reason = null
            // hide modal
            this.$refs['modalp_'].hide()
          } 
        })
      } catch (error) {
        // TypeError: Failed to fetch
        console.warn('There was an error editing permission', error);
      }
    },
    newLinesNames(n) {
      if (n) {
        return n.replace(/,/g, '<br>')
      }
      return ''
    },
    dismissedAlert() {
      this.dismissCountDown=0
      location.reload()
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert(msg) {
      this.dismissCountDown = this.dismissSecs
      this.alertMsg=msg
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFilteredPartner(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsPartner = filteredItems.length
      this.currentPageP = 1
    },
    onFilteredMember(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsMember = filteredItems.length
      this.currentPageM = 1
    },
    onFilteredMemberPartner(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsMemberPartner = filteredItems.length
      this.currentPagePM = 1
    },
    onFilteredProducts(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsProducts = filteredItems.length
      this.currentPagePr = 1
    },
    onFilteredTransactions(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsTransactions = filteredItems.length
      this.currentPageTr = 1
    },
    onFilteredExp(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsExpProducts = filteredItems.length
      this.currentPageExp = 1
    },
    onFilteredCommi(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsCommi = filteredItems.length
      this.currentPageCommi = 1
    },
    onFilteredSanctions(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsSanctions = filteredItems.length
      this.currentPageSanctions = 1
    },
    onFilteredDebts(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsDebts = filteredItems.length
      this.currentPageDebts = 1
    },
    onFilteredPermissions(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsPermissions= filteredItems.length
      this.currentPagePermissions = 1
    },
    onFilteredReserv(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsReserv= filteredItems.length
      this.currentPageReserv = 1
    },
  },
}
</script>
<style scoped>
  span.cancelled {
      background-color: #dc3545;
  }
  span.issued {
      background-color: #4abb9d;
  }
</style>
